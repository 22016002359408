<template>
	<!-- ticbar vertical -->
	<div id='barVert'  class='barVert'>
 		<div id='ytics'></div>
 	</div>
</template>



<script>

import $ from "jquery";

export default {

	data() {
		return {}
	},
	methods: {
	},
	mounted: function() {
		setVertTics()
		map.getView().on('change', setVertTics );
	},
	unmounted: function() {
		map.getView().un('change', setVertTics );
	}
}

function setVertTics () {
	if ( ! map.get('app').isPrintView ) {
			//unmounted is not called? 
			map.getView().un('change', setVertTics );
			map.get('coordRasterLayer').setMap( null ); //unmanned layer
			//map.get('tempLayer').setMap( null ); 
	}
	$("#ytics").empty()
	let rotation = map.getView().getRotation()
	if ( rotation !=  0 ) {
			//Tics don't work!!
			return
	}
	let extent = map.getView().calculateExtent( map.getSize());
	let mapHeight = $("#map").height(); 
	let yTop = extent[3]
	let yBottom = extent[1]
	let yDiff = yTop - yBottom;
	let yScale = mapHeight / yDiff;

	let ticYDiff = calcTicDiff( yTop, yBottom )
	let scale = 1 ; //TODO fill in
	let yPX
	let y = Math.floor(yBottom / ticYDiff ) * ticYDiff 
	let i = 0
	while (y < yTop) {
		yPX = (y - yBottom) * yScale;
		if (y >= yBottom  ) {
			$("#ytics").append("<div style='width: 15px; height: 1px; margin-bottom: -2px; border-top: solid 1px; bottom:"+yPX+"px;position:absolute;z-index:1004;'  '/>");
			$("#ytics").append("<div class='ytictext tictext'  id='yticlabel"+i+"' style='bottom:"+yPX+"px;'>"+y+"</div>");
			var textWidth = $("#yticlabel"+i).width();
			if (yPX < textWidth ) {
				$("#yticlabel" + i).remove();
			}
		}
		y += ticYDiff;
		i++;
	}
}

function calcTicDiff( max, min) {
	let diff = max - min;
	let pow = 0;
	let step = 1;
	while ((diff / (Math.pow(10,pow) * step)) > 7) {
		if (step == 1) {
			step = 2;
		} else if (step == 2) {
			step = 4;
		} else if (step == 4) {
			step = 5;
		} else if (step == 5) {
			step = 1;
			pow = pow + 1;
		}
	}
	return Math.pow(10,pow) * step;
}
</script>

<style>
</style>
