<template>
	<b-modal 
		@show="updateData"
		id="modal-list-features" 
		title="List Features">
 		<b-table striped hover :fields="fields" :items="xyzData"></b-table>
  	</b-modal>
</template>
<script>

export default {
	data()  {
		return { 
			xyzData	: [],
			 fields: ['type', 'X', 'Y', 'label', 'description'],
		}
	},
	methods: {
		updateData: function () {
			this.xyzData = []
			let layer = this.$parent.myObject
			if ( layer== null )  {
				return
			}
			let features = layer.getSource().getFeatures()
			for (let i = 0; i < features.length; i++ ) {
				let geometry = features[i].getGeometry()
				let label = features[i].get('label')
				let description = features[i].get('description')
				let type = geometry.getType()
				let x = 0
				let y = 0
				if ( type == "Point" ) {
					x = geometry.getFirstCoordinate()[0];
					y = geometry.getFirstCoordinate()[1];
				}
				this.xyzData.push( { 
					"type" 			: features[i].getGeometry().getType(),
					"X"				: x,
					"Y"				: y,	
					'label'			: label,
					'description'	: description
				} )		
			}

		}
	}
}
</script>
<style>
</style>
