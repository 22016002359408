var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-question",
      attrs: { id: "modal-question", title: "vraag" },
      on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk },
    },
    [
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.labeltext,
                "label-for": "name-input",
                "invalid-feedback": "Invalid input",
                state: _vm.answerState,
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "name-input",
                  state: _vm.answerState,
                  required: "",
                },
                model: {
                  value: _vm.$v.answer.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.answer, "$model", $$v)
                  },
                  expression: "$v.answer.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }