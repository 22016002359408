<template>
<div>
	<b-sidebar ref='layers-control-sidebar' no-header id="sidebar-1" style="top:auto" title="Lagen" v-model="isShown"  shadow>
     	<div class="px-3 py-2">

                <b-table
							v-b-hover           = 'hoverHandler'
							small
							ref					= "layers_control_table"
							thead-class="d-none"
                            hover 
                            :fields             ="mapLayers_dataFields"
                            :items              ="mapLayers_data"
							@row-clicked		="rowClicked"
							@row-dblclicked		="showModalLayerInfo"
							@row-contextmenu	="showModalLayerInfo"
							:tbody-tr-class     ="rowClass"
                             >
                        <template #cell(trash)="data">
                            <b-button  
                                size="sm" 
                                @click="removeLayer(data.item)"
                                title="verwijder laag">
                                <b-icon icon="trash" aria-hidden="true"></b-icon>
                            </b-button>
                        </template>
                        <template #cell(moveToTop)="data">
                            <b-button  
                                size="sm" 
                                @click="moveToTop(data.item)"
                                title="verplaats naar top">
                                <b-icon icon="chevron-bar-up" aria-hidden="true"></b-icon>
                            </b-button>
                        </template>

                        <template #cell(edit)="data">
                            <b-button  v-if="data.item.get('isEdited')"
								size="sm"
								@click="stopEdit( data.item )"
								variant="warning"
								title="stop bewerken">
                                <b-icon icon="pencil" aria-hidden="true"></b-icon>
                            </b-button>
								<div v-else >
									<div v-if="currentEditedLayer==null">
                            		<b-button  v-if="data.item.get('isEditable')"
										size="sm"
										@click="startEdit( data.item) "
										title="bewerk laag">
                                		<b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                            		</b-button>
									</div>
								</div>
							</button>
                        </template>
                        <template #cell(visibility)="data">
                            <b-button  
                                size="sm" 
                                @click="data.item.setVisible( ! data.item.getVisible() ) "
                                title="wissel zichtbaarheid">
                                <b-icon v-if="data.item.getVisible()" icon="eye" aria-hidden="true"></b-icon>
                                <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
                            </b-button>
                        </template>
                        <template #cell(name)="data">
							<div :class = "isHovered ? '': 'tableClassLayerName text-nowrap d-inline-block' ">
								<div :class = " viewResolution >  data.item.getMaxResolution() ? 'bluetext': '' ">
                                		{{data.value}}
							 		<b-spinner v-if="data.item.get('isGettingCapabilities')" small type="grow" label="Spinning"></b-spinner>
							 		<b-spinner v-if="data.item.get('isGettingFeatures')" small type="grow" variant="success" label="Spinning"></b-spinner>
							 		<b-spinner v-if="data.item.get('noOfTilesLoading')" small type="grow" variant="primary" label="Spinning"></b-spinner>
								</div>
							</div>
                        </template>
                </b-table>
      	</div>
    </b-sidebar>

</div>
</template>

<script>
import {saveFeatures}           from './wfsFunctions.js';


export default {
	data() {
		return {
 			mapLayers_dataFields            : [ { key: 'trash', },
 												{ key: 'moveToTop', },
 												{ key: 'edit', },
												{ key: 'visibility' },
												{
													key			:'name',
													formatter	: (value, key, item)=> {
														return item.get('name');
													}
												}],
    		mapLayers_data          		: [],
			isShown	 						: true,
			isHovered						: false
		}
	},
	computed: {
		viewResolution: function() {
			if ( map ) {
				return map.getView().getResolution()
			} else  {
				return Infinity
			}
		},
		currentEditedLayer: function() {
			return this.$parent.$refs.drawingTools.editedLayer
		}
	},
	methods: {
		hoverHandler: function( isHovered ) {
			this.isHovered = isHovered
		},
 		removeLayer: function( layer ) {
			if (layer.get('isEdited') ) {
				if (layer.get('isModified') && layer.get('isSaveable')) {
					this.$bvModal.msgBoxOk('Wijzigingen in deze laag zijn nog niet opgeslagen. Stop met bewerken en sla eventuele wijziging op alvorens de laag van de kaart te verwijderen.');
					return
				}
				this.$emit('stop-edit', layer );
			}
			if ( layer.controls) if ( layer.controls.info ) if (layer.controls.info.getActive()) {
				layer.controls.info.setActive( false );
			}
			map.removeLayer( layer );
        },
		moveToTop: function( layer ) {
			map.addLayer( map.removeLayer( layer ));
		},
		startEdit: function ( layer ) {
			if ( this.currentEditedLayer != null ) {
				if ( this.currentEditedLayer.get('isSaveable') && this.currentEditedLayer.get('isModified') ) {
 					this.$bvModal.msgBoxOk('U wilt een laag bewerken, maar stop eerst met bewerken van andere laag.');
				}
			}
			this.stopInfoAllLayers(); //stop some other info control
			this.$emit('start-edit', layer );
		},
		stopEdit: function ( layer ) {
			if ( layer.get('isSaveable') && layer.get('isModified') ) {
				this.$bvModal.msgBoxConfirm("WAARSCHUWING. Wijzigingen zijn niet opgeslagen! Eerst opslaan?")
				.then( saveFirst => {
					if ( saveFirst ) {
						saveFeatures( layer );
					} else {//don't save
						this.$emit('stop-edit', layer );
					}
					
				});
			} else {
				this.$emit('stop-edit', layer );
			}
		},

		showModalLayerInfo: function( layer, index, event ) {
            event.preventDefault();
            this.$root.$refs.modalStyling.show( layer, 'layerMode' );
        },
		rowClicked: function( layer, index, event ) {
			if ( event.ctrlKey || this.currentEditedLayer != null ) { //so single click also copies if a layer is edited
				this.$parent.$refs.drawingTools.moveObjectsToLayer( layer, 'copy' )
			} else if ( event.shiftKey ) {
				this.$parent.$refs.drawingTools.moveObjectsToLayer( layer, 'move' )
			} else { //toggle info control
            	event.preventDefault();
				if ( layer.controls) if ( layer.controls.info ) {
					if (!layer.controls.info.getActive() ) {
						this.stopInfoAllLayers(); //stop some other info control
						this.stopEditingAllLayers(); //or stop editing if so
						layer.controls.info.setActive( true );
        				if ( Object.getPrototypeOf( layer.controls.info ).constructor.name == "Select" ) {
							map.addInteraction( layer.controls.info );
						} 
					} else  {
						layer.controls.info.setActive( false );
        				if ( Object.getPrototypeOf( layer.controls.info ).constructor.name == "Select" ) {
							map.removeInteraction( layer.controls.info );
						} 
					}
					this.$forceUpdate();
				}
			}
		},
		stopEditingAllLayers: function() {
			for( var i = 0; i < map.getLayers().getLength(); i ++ ){
				let layer = map.getLayers().item( i );
				if (  layer.get('isEdited') ){
					this.$emit('stop-edit', layer );
				}
			}
			return;
		},
		stopInfoAllLayers: function() {
			for( var i = 0; i < map.getLayers().getLength(); i ++ ){
				let layer = map.getLayers().item( i );
				if ( layer.controls) if ( layer.controls.info ) if (layer.controls.info.getActive() ) {
					let infoControl = layer.controls.info
					infoControl.setActive( false );
        			if ( Object.getPrototypeOf( infoControl ).constructor.name == "Select" ) {
						map.removeInteraction( infoControl );
					} 
				}
			}
			return;
		},

		rowClass(layer, type) {
       		if ( !layer.getVisible() ) {
				return 'text-muted';
			} else if ( layer.get('isEdited')) {
				return 'table-primary';
			} else if ( layer.controls ) if (layer.controls.info ) if ( layer.controls.info.getActive()) {
				return 'table-success';
			} else {
				return;
			}
      	},

	},
	mounted: function () { 
		// We need this when switching from printview to normalview, otherwise maplayersdata=[]
		// and the layerscontrol is not visible
		if ( ! map || typeof map.getLayers === 'undefined' ){
			return
		}
		let collection = map.getLayers();
		let length = collection.getLength();
		for( let i = 0; i < length ; i++){
			this.$parent.$refs.layersControl.$data.mapLayers_data[ i ] =  collection.item( length - i - 1 );
			this.$parent.$refs.layersControl.$refs.layers_control_table.refresh()
		}
	}
}
</script>

<style>
</style>


