var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-gridinput",
      attrs: { id: "modal-gridinput", title: "Grid" },
      on: { show: _vm.resetModal, hidden: _vm.resetModal, ok: _vm.handleOk },
    },
    [
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "5",
                label: "Start nummering bij",
                "label-for": "firstnumber-input",
                "invalid-feedback": "required",
                state: _vm.firstNumberState,
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "firstnumber-input", required: "" },
                model: {
                  value: _vm.firstNumber,
                  callback: function ($$v) {
                    _vm.firstNumber = $$v
                  },
                  expression: "firstNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "5",
                label: "Lengte van het grid langs de lijn (m)",
                "label-for": "length-input",
                "invalid-feedback": "required",
                state: _vm.lengthState,
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "length-input", required: "" },
                model: {
                  value: _vm.length,
                  callback: function ($$v) {
                    _vm.length = $$v
                  },
                  expression: "length",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "Afstand tussen punten langs de lijn (m)",
                    "label-for": "intervalLength-input",
                    "invalid-feedback": "required",
                    state: _vm.intervalLengthState,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "intervalLength-input", required: "" },
                    model: {
                      value: _vm.intervalLength,
                      callback: function ($$v) {
                        _vm.intervalLength = $$v
                      },
                      expression: "intervalLength",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "5",
                label: "Breedte van het grid dwars op de lijn (m)",
                "label-for": "width-input",
                "invalid-feedback": "required",
                state: _vm.widthState,
              },
            },
            [
              _c("b-form-input", {
                attrs: { id: "width-input", required: "" },
                model: {
                  value: _vm.width,
                  callback: function ($$v) {
                    _vm.width = $$v
                  },
                  expression: "width",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "Afstand tussen de lijnen (m)",
                    "label-for": "intervalWidth-input",
                    "invalid-feedback": "required",
                    state: _vm.intervalWidthState,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "intervalWidthLength-input", required: "" },
                    model: {
                      value: _vm.intervalWidth,
                      callback: function ($$v) {
                        _vm.intervalWidth = $$v
                      },
                      expression: "intervalWidth",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "5",
                label: "Maak ALLEEN punten langs een lijn",
                "label-for": "linegrid-checkbox",
              },
            },
            [
              _c("b-form-checkbox", {
                attrs: { id: "linegrid-checkbox" },
                model: {
                  value: _vm.isLineGrid,
                  callback: function ($$v) {
                    _vm.isLineGrid = $$v
                  },
                  expression: "isLineGrid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "5",
                label: "Maak sleuven (stippellijn)",
                "label-for": "sleuven-checkbox",
              },
            },
            [
              _c("b-form-checkbox", {
                attrs: { id: "sleuven-checkbox" },
                model: {
                  value: _vm.isSleuvenGrid,
                  callback: function ($$v) {
                    _vm.isSleuvenGrid = $$v
                  },
                  expression: "isSleuvenGrid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "sleufbreedte",
                    "label-for": "sleufBreedte-input-",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "sleufBreedte-input" },
                    model: {
                      value: _vm.sleufBreedte,
                      callback: function ($$v) {
                        _vm.sleufBreedte = $$v
                      },
                      expression: "sleufBreedte",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "sleuflengte",
                    "label-for": "sleufLengte-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "sleufLengte-input" },
                    model: {
                      value: _vm.sleufLengte,
                      callback: function ($$v) {
                        _vm.sleufLengte = $$v
                      },
                      expression: "sleufLengte",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "sleufafstand",
                    "label-for": "sleufAfstand-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "sleufAfstand-input" },
                    model: {
                      value: _vm.sleufAfstand,
                      callback: function ($$v) {
                        _vm.sleufAfstand = $$v
                      },
                      expression: "sleufAfstand",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSleuvenGrid
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "5",
                    label: "sleufinterval",
                    "label-for": "sleufInterval-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "sleufInterval-input" },
                    model: {
                      value: _vm.sleufInterval,
                      callback: function ($$v) {
                        _vm.sleufInterval = $$v
                      },
                      expression: "sleufInterval",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }