<template>

	<b-modal 
		no-fade
		id="modal-zoek-plaats" @ok="clickedOK" scrollable size="xl" v-model="isShown"  title="Zoek een plaats">
		<b-form-input v-model="searchString" v-on:keyup="searchLocation" placeholder="PLAATS" autofocus></b-form-input>
			<b-table  
					compact
					ref					="searchLocation_table"
                    hover 
                    selectable 
					select-mode			="single"
					:fields				="dataFields"
					thead-class			="d-none" 
                    :items				="tableData"
					@row-clicked		="zoomToLocation" >
            </b-table>
  	</b-modal>
</template>


<script>

import WKT 							from 'ol/format/WKT';

export default {

	data() {
		return {
			searchString	: "",
			tableData		: [],
			dataFields		: ["weergavenaam"],
			selectedRow		: -1,
			isShown			: false,
        	awaitingSearch	: false
		}
	},
	methods: {
		clickedOK: function () {
			if (this.selectedRow > -1 ) {
				this.zoomToLocation(  this.tableData[ this.selectedRow ] );
			}
		},
    	searchLocation: function (message) {
			if (message.key == "ArrowDown") {
				this.selectedRow = this.selectedRow + 1;
				if ( this.selectedRow >= this.tableData.length ) {
					this.selectedRow = 0;
				}
				this.$refs['searchLocation_table'].selectRow( this.selectedRow);
			} else if (message.key == "ArrowUp") {
				this.selectedRow = this.selectedRow - 1;
				if ( this.selectedRow < 0 ){
					this.selectedRow = this.tableData.length - 1;
				}
				this.$refs['searchLocation_table'].selectRow( this.selectedRow);
			} else if (message.key == "Enter") {
				this.zoomToLocation(  this.tableData[ this.selectedRow ] );
			} else {
				this.selectedRow = -1;
				this.$refs['searchLocation_table'].clearSelected();

				if (!this.awaitingSearch) {
          				setTimeout(() => {
            				this.fetchResults( this.searchString );
            				this.awaitingSearch = false;
          				}, 500); // 0.5 sec delay
				}
        		this.awaitingSearch = true;
			}
       	},
		zoomToLocation: function( selectedLocations ) {
			this.searchString = ""
			let locationID = selectedLocations.id
			fetch('https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=' + locationID )
			.then ( response => response.json() )
			.then ( data => {
				let wkt = data.response.docs[0].centroide_rd //get first hit
				//let wkt = data.centroide_rd;
				var format = new WKT();
				var feature = format.readFeature(wkt, {
  						dataProjection: 'EPSG:28992',
  						featureProjection: 'EPSG:28992',
				});
				map.getView().fit( feature.getGeometry(), { maxZoom:12 } );
				this.isShown = false;
			})
		},
		fetchResults: function( searchString ) {
			fetch('https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=' + searchString )
			//fetch('https://geodata.nationaalgeoregister.nl/locatieserver/v3/free?q=' + searchString)
           	 	.then( response => response.json())
           	 	.then( data => {
           	   		if ( typeof data.response.docs !== 'undefined' ) {
           	        		this.tableData = data.response.docs;
           	    		} else {
           	        		this.tableData = [];
           	    		}
           	 	});
		}
	}
}
</script>

<style>
</style>
