var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isDisabled
        ? _c(
            "b-form-group",
            { attrs: { id: "buttongroup" } },
            [
              _c(
                "b-form-radio-group",
                {
                  staticClass: "h-100",
                  attrs: {
                    disabled: _vm.isDisabled,
                    buttons: "",
                    "button-variant": "warning",
                  },
                  on: { change: _vm.toggleControl },
                  model: {
                    value: _vm.checkedValue,
                    callback: function ($$v) {
                      _vm.checkedValue = $$v
                    },
                    expression: "checkedValue",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Point", title: "Teken punten" },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "dot",
                          scale: "3",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "LineString", title: "Teken een lijn" },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "check2",
                              scale: "1.2",
                              "shift-h": "-6",
                              "shift-v": "-4",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "check2",
                              scale: "1.2",
                              "shift-h": "7",
                              "shift-v": "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-12",
                              "shift-v": "-4",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-8",
                              "shift-v": "-7.5",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "1",
                              "shift-v": "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "5",
                              "shift-v": "-2.5",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "14",
                              "shift-v": "6",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Polygon", title: "Teken polygoon" },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "pentagon",
                              scale: "1.2",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-5",
                              "shift-v": "-9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "+5",
                              "shift-v": "-9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "+0",
                              "shift-v": "+9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-9",
                              "shift-v": "+2",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "+9",
                              "shift-v": "+2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Rectangle", title: "Teken rechthoeken" },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "square",
                              scale: "1.2",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "9",
                              "shift-v": "9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-9",
                              "shift-v": "-9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "9",
                              "shift-v": "-9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "-9",
                              "shift-v": "9",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: {
                        value: "Modify",
                        title:
                          "Wijzig knopen van lijnen, polygonen en rechthoeken",
                      },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: { stacked: "", icon: "cursor", scale: "1" },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "dot",
                              scale: "1.5",
                              "shift-h": "8",
                              "shift-v": "8",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Select", title: "Selecteer objecten" },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "cursor",
                          scale: "1.2",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: {
                        value: "EditAttribute",
                        title: "Bewerk atributen",
                      },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "cursor",
                              scale: "0.9",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: {
                              stacked: "",
                              icon: "info",
                              "shift-h": "-9",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: {
                        value: "Translate",
                        title: "Verplaats objecten",
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "arrows-move", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Rotate", title: "Draai objecten" },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "arrow-clockwise",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" },
                      ],
                      attrs: { value: "Del", title: "Verwijder objecten" },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "trash", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showObjectSize
                    ? _c("b-badge", { attrs: { variant: "info" } }, [
                        _vm._v(" " + _vm._s(_vm.objectSizeHTML) + " "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSelectionSize
                    ? _c("b-badge", { attrs: { variant: "info" } }, [
                        _vm._v(" " + _vm._s(_vm.selectedSize) + " "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.renumberingIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: { title: "Nummer objecten" },
                      on: { click: _vm.renumber },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "hash", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.gridIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: { title: "Maak een verspringend grid" },
                      on: { click: _vm.grid },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "grid3x3-gap", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.bufferIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: { title: "vergroot het object met een buffer" },
                      on: { click: _vm.buffer },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "bullseye", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.unionIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: { title: "voeg de objecten samen" },
                      on: { click: _vm.union },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "link", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.differenceIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: { title: "bereken verschil tussen twee objecten" },
                      on: { click: _vm.difference },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "subtract", "aria-hidden": "true" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.breakIsEnabled
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        title:
                          "splits het samengestelde object in afzonderlijke objecten",
                      },
                      on: { click: _vm.breakFeatureAction },
                    },
                    [
                      _c(
                        "b-iconstack",
                        [
                          _c("b-icon", {
                            attrs: { stacked: "", icon: "link" },
                          }),
                          _vm._v(" "),
                          _c("b-icon", {
                            attrs: { stacked: "", icon: "scissors" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.layerCanBeSaved
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger", value: "Save" },
                      on: { click: _vm.save },
                    },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "box-arrow-in-down",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("modal-gridinput", { ref: "modalGridinput" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }