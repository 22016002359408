<template>
	<div>
	<b-modal  ok-only id="modal-beheer-lagen" title="Beheer lagen" scrollable size="xl" v-model="isShown" >

	<b-row>
      	<b-col sm="7" md="6" class="my-1">
		  <div v-if='$root.userdata && ($root.userdata.maxNrLayers > totalRows)'>
      		<b-button @click='$refs.modalEditLayer.add("user")'>+VectorLaag</b-button>
      		<b-button @click='$refs.modalEditLayer.add("image")'>+ScanLaag</b-button>
		  </div>
		  <div v-if='$root.userdata && ($root.userdata.maxNrLayers <= totalRows)'>
			[max number of layers exceeded. please contact system admin]
		  </div>
      	</b-col>
	</b-row>
	
	<b-overlay :show="tableIsBusy" rounded="sm">

	<b-row>

 		<b-col lg="6" class="my-1">
        	<b-form-group
          		label="Filter"
          		label-for="filter-input"
          		label-cols-sm="3"
          		label-align-sm="right"
          		label-size="sm"
          		class="mb-0" >
          		<b-input-group size="sm">
            		<b-form-input
              				id="filter-input"
              				v-model="filter"
              				type="search"
              				placeholder="Type to Search"
            		></b-form-input>
          		</b-input-group>
        	</b-form-group>
      	</b-col>


      	<b-col sm="7" md="6" class="my-1">
        	<b-pagination
          		v-model			="currentPage"
          		:total-rows		="totalRows"
          		:per-page		= 10
          		align			="fill"
          		size			="sm"
          		class			="my-0">
			</b-pagination>
      	</b-col>
	</b-row>



		<b-table  
			small
			hover 
			ref					="layerstable"
			:current-page		="currentPage"
			:per-page			="perPage"
			:filter				="filter"
			:fields				="tableDataFields"
			:items				="myProvider"
			:sort-by.sync		="tableSortBy"
			@row-clicked		="handleLayer" >
          </b-table>
	   </b-overlay >
		<modal-edit-layer  ref='modalEditLayer'	@changelayersdb="refresh"> </modal-edit-layer>
  	</b-modal>
	<b-modal ref="modal-ask-layeraction" hide-footer title="Wat wilt u met deze laag doen?">
		<b-card>{{ handledLayerName }} </b-card>
      	<b-card> 
			<b-button variant="primary" 	@click="editLayer">Bewerken</b-button>
      		<b-button variant="danger" 	@click="deleteLayer">Verwijderen</b-button>
      		<b-button 					@click="$refs['modal-ask-layeraction'].hide()">terug</b-button>
		</b-card>
    </b-modal>
	</div>
</template>

<script>

import ModalEditLayer       from './modal_edit_layer.vue';

export default {
	data() {
		return {
			tableData			: [],
			tableDataFields		: [
					{ key: "name", 			sortable: true },
					{ key: "type",  		sortable: false },
					{ key: "groupname", 	sortable: false },
					{ key: "access", 		sortable: false },
					{ key: "description", 	sortable: false},
					{ key: "id", 			sortable: false },
				],
 			tableSortBy			: 'name',
			selectedRow			: -1,
			isShown				: false,
			tableIsBusy			: false,
			filter				: '',
			totalRows			: 1,
        	currentPage			: 1,
        	perPage				: 10,
			handledRow			: null,
			handledLayerName	: ""
		}
	},
	methods: {
		refresh: function () {
			this.$refs.layerstable.refresh()
		},
		closeModalAskLayer: function() {
			this.$refs['modal-ask-layeraction'].hide()
		},
		handleLayer: function ( row ) {
			this.handledRow = row
			this.handledLayerName = row.name
			this.$refs['modal-ask-layeraction'].show()
		},
		editLayer: function ( ) {
			this.$refs['modal-ask-layeraction'].hide()
			if ( this.handledRow.type == 'user' ) {
				this.$refs['modalEditLayer'].edit( this.handledRow );
			}
			else if ( this.handledRow.type == 'image' ) {
				this.$refs['modalEditLayer'].edit( this.handledRow );
			}
		}, 
		deleteLayer: function () {
			this.$refs['modal-ask-layeraction'].hide();
			this.$bvModal.msgBoxConfirm('Dit kan niet ongedaan worden gemaakt. Laag "' + this.handledRow.name + '" echt verwijderen?', { okTitle: 'Verwijder', okVariant: 'danger'})
          	.then( confirmed => {
            	if ( confirmed ) {
					this.$refs.layerstable.refresh();
       	 			fetch('submitLayerMetaData.php', {
						method	: 'post',
						headers	: { 'Content-Type': 'application/json' },
						body	: JSON.stringify( [ {
							action	: "delete",
							name	: this.handledRow.name,
							id		: this.handledRow.id
						}])
					})
					.then( response => response.json())
					.then( data => {
						this.showOverlay = false;
						if ( data[0].success == "true" ) {
							this.$bvModal.msgBoxOk( "Laag: '" + this.handledRow.name + "' succesvol verwijderd." );
						} else if ( data[0].success == "false" ) {
							this.$bvModal.msgBoxOk( "Fout verwijderen laag: '" + this.handledRow.name + "'. De server meldt:" + data[0].error );
						}
    				})
				}
			});
		},
		myProvider: function ( ctx, callback ) {
			const params = '&page=' + ctx.currentPage + '&size=' + ctx.perPage + '&s=' + ctx.filter;
  			fetch('getFilteredLayers.php?editable' + params)
				.then( response => response.json())
           	 	.then( data => {
      				// Pluck the array of items off our axios response
					this.totalRows = data.pop();
      				const items = data;
      				// Provide the array of items to the callback
      				callback(items);
    		})
    		.catch(() => {
      			callback([]);
    		})
  			// Must return null or undefined to signal b-table that callback is being used
  			return null
		},

	},
  	components: { ModalEditLayer }
}

</script>

<style>
</style>
