'use strict'

import $ from "jquery";

export function setMapTics() {
	var extent = map.getView().calculateExtent( map.getSize());
	var rotation = map.getView().getRotation();
	var mapHeight = $("#map").height(); 
	var mapWidth = $("#map").width();
	var yTop = extent[3];
	var yBottom = extent[1];
	var yDiff = yTop - yBottom;
	var yScale = mapHeight / yDiff;
	var pow = 0;
	var step = 1;
	var scale = 1 ; //TODO fill in
	var yPX;
	var xPX;


	//lets move the map from the left screen, to make space for tics
	//$("#map").css("left","14px")

	while ((yDiff / (Math.pow(10,pow) * step)) > 7) {
		if (step == 1) {
			step = 2;
		} else if (step == 2) {
			step = 4;
		} else if (step == 4) {
			step = 5;
		} else if (step == 5) {
			step = 1;
			pow = pow + 1;
		}
	}
	var ticYDiff = Math.pow(10,pow) * step;
	var y = Math.floor(yBottom / ticYDiff) * ticYDiff
	var y0 = y;
	//var y = Math.floor((yBottom / Math.pow(10,pow))) * Math.pow(10,pow);
	//if ((y % ticYDiff) != 0) y = Math.((yBottom / Math.pow(10,pow))) * Math.pow(10,pow);
	var borderW = 1.0 * scale;
	var barW = 14 * scale;
	$("#map #tics").remove()
	//$("#map #barVert").remove()
	//$("#map #barHor").remove()
		$("#map").append("<div id='tics'></div>");
		//$("#map").append("<div id='barVert' class='barVert'></div>");
		//$("#map").append("<div id='barHor'  class='barHor'></div>"); 
	$("#map #tics").empty();
	$("#map #barHor").empty();
	$("#map #barVert").empty();
	var i=0;
	var fontSize = 1.0 * scale;
	while (y < yTop) {
		yPX = (y - yBottom) * yScale;
		if (y >= yBottom ) {
			$("#map #tics").append("<div style='width: 15px; height: 1px; border-top: solid 1px; bottom:"+yPX+"px;position:absolute;z-index:1004;'  '/>");
			$("#map #tics").append("<div class='ytictext tictext'  id='yticlabel"+i+"' style='bottom:"+yPX+"px;'>"+y+"</div>");
			var textWidth = $("#yticlabel"+i).width();
			var textHeight = $("#yticlabel"+i).height();
			if (yPX < textWidth ) {
				$("#yticlabel" + i).remove();
			} else {
				//cssSandpaper.setTransform($("#yticlabel"+i).get()[0], "rotate(90deg) translate(-"+(textWidth/2)+"px,"+(textHeight-1)+"px)");
			}
		}




		y += ticYDiff;
		i++;
	}

	var xRight = extent[2];
	var xLeft = extent[0];
	var xDiff= xRight - xLeft;
	var xScale= mapWidth / xDiff;
	var ticXDiff = Math.pow(10,pow) * step;
	//var x = Math.floor((xLeft / Math.pow(10,pow))) * Math.pow(10,pow);
	var x = Math.floor((xLeft / ticXDiff)) * ticXDiff;
	while (x < xRight) {
		xPX = (x - xLeft) * xScale;
		if (x >= xLeft) {
			//$("#map #tics").append("<img style='bottom:0px;left:"+xPX+"px;position:absolute;z-index:1006;' src='images/ticMarkVert1x10.png'/>");
			$("#map #tics").append("<div style='width: 1px; height: 15px; border-left: solid 1px; bottom: 0px; left:"+xPX+"px;position:absolute;z-index:1006;'  '/>");
			$("#map #tics").append("<div class='tictext' id='xticlabel"+i+"'style='padding-left: 1px; bottom:0px;left:"+xPX+"px;position:absolute;z-index:1006;' >"+x+"</div>");
			var textWidth = $("#xticlabel"+i).width();
			var textHeight = $("#xticlabel"+i).height();
			if (xPX + textWidth > mapWidth) {
				$("#xticlabel" + i).remove();
			} 
		}
		x += ticXDiff;
		i++;
	}

	if ( rotation > 0 ) {
			var canvas = document.getElementById("myCanvas");
			var ctx = canvas.getContext("2d");
			startPx = map.getPixelFromCoordinate( [x0, y0 ] )
			endPx = map.getPixelFromCoordinate( [xRight, y0 ] )
			ctx.beginPath();
			ctx.moveTo( startPx[0], startPx[1]);
			ctx.lineTo( endPx[0], endPx[1])
			// Draw the Path
			ctx.stroke();

			startPx = map.getPixelFromCoordinate( [x0, y0+ticYDiff ] )
			endPx = map.getPixelFromCoordinate( [xRight, y0+ticYDiff ] )
			ctx.beginPath();
			ctx.moveTo( startPx[0], startPx[1])
			ctx.lineTo( endPx[0], endPx[1])
			// Draw the Path
			ctx.stroke();

	}

}
