var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-download-gpx-data", title: "Download data" },
      on: { show: _vm.updateData },
    },
    [
      _vm._v("\n\t\t Hier staat uw data: "),
      _c("b-link", { attrs: { href: _vm.link } }, [_vm._v("Link")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }