var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-sign-in",
        "hide-footer": "",
        "no-fade": "",
        "hide-header-close": "",
        title: "Aanmelden",
      },
      on: { show: _vm.update },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.showOverlay, rounded: "sm" } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onGuest } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-1",
                        label: "email:",
                        "label-for": "input-1",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-1",
                          type: "email",
                          placeholder: "Email",
                          autofocus: "",
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-2",
                        label: "paswoord:",
                        "label-for": "input-2",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-2",
                          type: "password",
                          placeholder: "paswoord",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Aanmelden")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    { attrs: { type: "reset", variant: "danger" } },
                    [_vm._v("Gastgebruiker")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.noOfPasswordAttempts > 0
            ? _c(
                "b-card",
                [
                  _c("b-button", { on: { click: _vm.requestNewPassword } }, [
                    _vm._v("Vraag nieuw paswoord"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }