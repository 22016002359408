var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "ok-only": "",
            id: "modal-beheer-lagen",
            title: "Beheer lagen",
            scrollable: "",
            size: "xl",
          },
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                [
                  _vm.$root.userdata &&
                  _vm.$root.userdata.maxNrLayers > _vm.totalRows
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.modalEditLayer.add("user")
                                },
                              },
                            },
                            [_vm._v("+VectorLaag")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.modalEditLayer.add("image")
                                },
                              },
                            },
                            [_vm._v("+ScanLaag")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$root.userdata &&
                  _vm.$root.userdata.maxNrLayers <= _vm.totalRows
                    ? _c("div", [
                        _vm._v(
                          "\n\t\t\t[max number of layers exceeded. please contact system admin]\n\t\t  "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-overlay",
            { attrs: { show: _vm.tableIsBusy, rounded: "sm" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "my-1", attrs: { lg: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            label: "Filter",
                            "label-for": "filter-input",
                            "label-cols-sm": "3",
                            "label-align-sm": "right",
                            "label-size": "sm",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { size: "sm" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "filter-input",
                                  type: "search",
                                  placeholder: "Type to Search",
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
                    [
                      _c("b-pagination", {
                        staticClass: "my-0",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": 10,
                          align: "fill",
                          size: "sm",
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-table", {
                ref: "layerstable",
                attrs: {
                  small: "",
                  hover: "",
                  "current-page": _vm.currentPage,
                  "per-page": _vm.perPage,
                  filter: _vm.filter,
                  fields: _vm.tableDataFields,
                  items: _vm.myProvider,
                  "sort-by": _vm.tableSortBy,
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.tableSortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.tableSortBy = $event
                  },
                  "row-clicked": _vm.handleLayer,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("modal-edit-layer", {
            ref: "modalEditLayer",
            on: { changelayersdb: _vm.refresh },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal-ask-layeraction",
          attrs: { "hide-footer": "", title: "Wat wilt u met deze laag doen?" },
        },
        [
          _c("b-card", [_vm._v(_vm._s(_vm.handledLayerName) + " ")]),
          _vm._v(" "),
          _c(
            "b-card",
            [
              _c(
                "b-button",
                { attrs: { variant: "primary" }, on: { click: _vm.editLayer } },
                [_vm._v("Bewerken")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: { click: _vm.deleteLayer },
                },
                [_vm._v("Verwijderen")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs["modal-ask-layeraction"].hide()
                    },
                  },
                },
                [_vm._v("terug")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }