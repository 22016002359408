
<template>
	<!-- zoek laag modal -->
	<b-modal 
		no-fade
		id="modal-zoek-laag" scrollable size="xl" v-model="isShown"  title="Zoek een laag">

    	<b-form @submit="onSubmit">
				<b-form-group label="Zoek in:" >
      				<b-form-radio @change="$refs.searchLayer_table.refresh()" inline v-model="searchTarget" name="target" value="AOK">AOK</b-form-radio>
      				<b-form-radio @change="$refs.searchLayer_table.refresh()" inline v-model="searchTarget" name="target" value="PDOK">PDOK</b-form-radio>
    			</b-form-group>
		</b-form>

	<b-row v-on:keyup="searchLayer">
 		<b-col lg="6" class="my-1">
        	<b-form-group
          		label					="Filter"
          		label-for				="filter-input"
          		label-cols-sm			="3"
          		label-align-sm			="right"
          		label-size				="sm"
          		class					="mb-0" >
          		<b-input-group size="sm">
            		<b-form-input
              				id			="filter-input"
              				v-model		="filter"
              				type		="search"
              				placeholder	="trefwoord"
							autofocus
            		></b-form-input>
          		</b-input-group>
        	</b-form-group>
      	</b-col>

 	 	<b-col sm="7" md="6" class="my-1">
            <b-pagination
                v-model         ="currentPage"
                :total-rows     ="totalRows"
                :per-page       = 10
                align           ="fill"
                size            ="sm"
                class           ="my-0">
            </b-pagination>
       </b-col>
	</b-row>
		<b-table  
					compact
					:current-page		="currentPage"
					:per-page			="perPage"
					:filter				="filter"
					:items				="myProvider"
					ref					="searchLayer_table"
                    hover 
                    selectable 
					select-mode			="single"
					:fields				="tableDataFields"
					@row-dblclicked		="doubleClick" 
					@row-clicked		="singleClick"  >
           </b-table>
  	</b-modal>
	<!-- END zoek laag modal -->
</template>

<script>

import {createLayer}				from './aokFunctions';

let time = null //distinghuish between click and dbl-click, see: https://www.programmersought.com/article/82011365852/

export default {
	data() {
		return {
			totalRows			: 1,
        	currentPage			: 1,
        	perPage				: 10,
			searchString				: "",
			filter				: '',
			tableData			: [],
			tableDataFields		: ["name","type","description","userid"],
			selectedRow			: -1,
			isShown				: false,
			awaitingSearch		: false,
			zoomToExtent		: false,
			searchTarget		: "AOK"
		}
	},
	methods: {
		// click event
		singleClick(row,column,event,cell){
                clearTimeout(time)
                time = setTimeout(() => {
                       this.loadLayer( row, column, event, cell )
                }, 300);
          },
		 // double click event
		doubleClick(row,column,event,cell){
                clearTimeout(time);
                this.loadLayerZoom( row, column, event, cell )
        },

		onSubmit: function ( evt ) {
			evt.preventDefault()
		},
		//TODO: reactivate key control
    	searchLayer: function (message) {
			message.preventDefault()
			if (message.key == "ArrowDown") {
				this.selectedRow = this.selectedRow + 1;
				if ( this.selectedRow >= this.tableData.length ) {
					this.selectedRow = 0;
				}
				this.$refs['searchLayer_table'].selectRow( this.selectedRow);
			} else if (message.key == "ArrowUp") {
				this.selectedRow = this.selectedRow - 1;
				if ( this.selectedRow < 0 ){
					this.selectedRow = this.tableData.length - 1;
				}
				this.$refs['searchLayer_table'].selectRow( this.selectedRow);
			} else if (message.key == "Enter") {
				if (this.selectedRow > -1 ) {
					this.loadLayer(  this.tableData[ this.selectedRow ] );
				} else if (this.tableData.length == 1 ) {
					this.loadLayer(  this.tableData[ 0 ] );
				}
			} 
       	},
		loadLayerZoom:	function( layerData, row, evtObject ) {
			console.log('loadlayerzoom')
			if (evtObject) {
				evtObject.preventDefault() 
			}
			this.zoomToExtent = true
			this.loadLayer(  layerData )
		},
		loadLayer:	function( layerData ) {
			console.log('loadlayer')
			this.searchString = ""
			let layer = createLayer( layerData )
			if ( this.zoomToExtent ) {
				layer.once( 'change', function() {
					map.getView().fit( layer.getSource().getExtent(), { minResolution: 0.21 }) 
				}) 
				this.zoomToExtent = false
			}
			map.addLayer( layer ) 
			this.isShown = false;
		}, 
		fetchResults: function( ctx, callback ) {
			let searchKey = ctx.filter.replace(/\s+/g, ' ').trim()
			const params = '&page=' + ctx.currentPage + '&size=' + ctx.perPage + '&s=' + searchKey + '&target=' + this.searchTarget;
  			fetch('getFilteredLayers.php?' + params)
				.then( response => response.json())
           	 	.then( data => {
      				// Pluck the array of items off our axios response
					this.totalRows = data.pop();
      				//const items = data
      				// Provide the array of items to the callback
      				//callback( items )
      				callback( data )
					this.tableData = data //to be able to use key select
    		})
    		.catch(() => {
      			callback([]);
				this.tableData = data
    		})
		},
		myProvider: function ( ctx, callback ) {
			if (!this.awaitingSearch) { //https://techformist.com/wait-for-user-to-stop-typing-in-search-fields-on-vue-ui/
          			setTimeout(() => {
            			this.fetchResults( ctx, callback );
            			this.awaitingSearch = false;
          			}, 500); // 0.5 sec delay
        		this.awaitingSearch = true
  				// Must return null or undefined to signal b-table that callback is being used
  				return null
			}
		},
	}
}
</script>

<style>
</style>
