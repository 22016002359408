var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "no-fade": "",
        id: "modal-zoek-plaats",
        scrollable: "",
        size: "xl",
        title: "Zoek een plaats",
      },
      on: { ok: _vm.clickedOK },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c("b-form-input", {
        attrs: { placeholder: "PLAATS", autofocus: "" },
        on: { keyup: _vm.searchLocation },
        model: {
          value: _vm.searchString,
          callback: function ($$v) {
            _vm.searchString = $$v
          },
          expression: "searchString",
        },
      }),
      _vm._v(" "),
      _c("b-table", {
        ref: "searchLocation_table",
        attrs: {
          compact: "",
          hover: "",
          selectable: "",
          "select-mode": "single",
          fields: _vm.dataFields,
          "thead-class": "d-none",
          items: _vm.tableData,
        },
        on: { "row-clicked": _vm.zoomToLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }