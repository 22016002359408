<template>
 <b-modal
      id			="modal-gridinput"
      ref			="modal-gridinput"
      title			="Grid"
      @show			="resetModal"
      @hidden		="resetModal"
      @ok			="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-cols="5" label="Start nummering bij"					   label-for="firstnumber-input"    invalid-feedback="required" :state="firstNumberState">      <b-form-input id ="firstnumber-input"         v-model="firstNumber"    required ></b-form-input>    </b-form-group>
        <b-form-group label-cols="5" label="Lengte van het grid langs de lijn (m)"     label-for="length-input"         invalid-feedback="required" :state="lengthState">        	<b-form-input id ="length-input"              v-model="length"         required ></b-form-input>    </b-form-group>
        <b-form-group v-if=" ! isSleuvenGrid" label-cols="5" label="Afstand tussen punten langs de lijn (m)"   label-for="intervalLength-input" invalid-feedback="required" :state="intervalLengthState">	<b-form-input id ="intervalLength-input"      v-model="intervalLength" required ></b-form-input>    </b-form-group>
        <b-form-group label-cols="5" label="Breedte van het grid dwars op de lijn (m)" label-for="width-input"          invalid-feedback="required" :state="widthState">          	<b-form-input id ="width-input"               v-model="width"          required ></b-form-input>    </b-form-group>
        <b-form-group v-if=" ! isSleuvenGrid" label-cols="5" label="Afstand tussen de lijnen (m)"              label-for="intervalWidth-input"  invalid-feedback="required" :state="intervalWidthState"> 	<b-form-input id ="intervalWidthLength-input" v-model="intervalWidth"  required ></b-form-input>    </b-form-group>
        <b-form-group label-cols="5" label="Maak ALLEEN punten langs een lijn"         label-for="linegrid-checkbox">                                                               <b-form-checkbox id ="linegrid-checkbox"      v-model="isLineGrid">              </b-form-checkbox> </b-form-group>
        <b-form-group label-cols="5" label="Maak sleuven (stippellijn)"        		   label-for="sleuven-checkbox">                                                                <b-form-checkbox id ="sleuven-checkbox"       v-model="isSleuvenGrid">            </b-form-checkbox> </b-form-group>
        <b-form-group v-if="isSleuvenGrid" label-cols="5" label="sleufbreedte"         			   		   label-for="sleufBreedte-input-">                                                             <b-form-input id ="sleufBreedte-input"      v-model="sleufBreedte">            	 </b-form-input> </b-form-group>
        <b-form-group v-if="isSleuvenGrid" label-cols="5" label="sleuflengte"           			   		   label-for="sleufLengte-input">                                                               <b-form-input id ="sleufLengte-input"     v-model="sleufLengte">               </b-form-input> </b-form-group>
        <b-form-group v-if="isSleuvenGrid" label-cols="5" label="sleufafstand"           			   	   label-for="sleufAfstand-input">                                                              <b-form-input id ="sleufAfstand-input"   v-model="sleufAfstand">               </b-form-input> </b-form-group>
        <b-form-group v-if="isSleuvenGrid" label-cols="5" label="sleufinterval"           			   	   label-for="sleufInterval-input">                                                             <b-form-input id ="sleufInterval-input"           v-model="sleufInterval">               </b-form-input> </b-form-group>
		
      </form>
    </b-modal>

</template>

<script>

export default {
	data() {
		return {
			firstNumber		: 1,
			firstNumberState		: null,
			length			: "",
			lengthState		: null,
			intervalLength	: "",
			intervalLengthState	: null,
			width			: "",
			widthState		: null,
			intervalWidth	: "",
			intervalWidthState	: null,
			isLineGrid			: false,
			isSleuvenGrid		: false,
			resolvePromise		: null,
			rejectPromise		: null,
			sleufBreedte		: null,
			sleufLengte	   		: null,
			sleufAfstand		: null,
			sleufInterval		: null
		}
	},
	methods: {
		checkFormValidity() {
        	const valid = this.$refs.form.checkValidity()
        	this.answerState = valid
        	return valid
		},
		resetModal() {
			Object.assign(this.$data, this.$options.data.call(this)); //reset data to inital values
		},
		handleSubmit() {
			if (!this.checkFormValidity()) {
				return
			}
			// Hide the modal manually
			this.$nextTick(() => {
				this.$bvModal.hide('modal-gridinput')
				this.resolvePromise( this.$data )
			})
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
				this.handleSubmit()
		},
		askGridProps(  ) {
 			return new Promise((resolve, reject) => {
				this.$bvModal.show('modal-gridinput')
        		this.resolvePromise = resolve;
        		this.rejectPromise = reject;
      	});
		}
	}
}
</script>

<style>
</style>
