var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          ref: "layers-control-sidebar",
          staticStyle: { top: "auto" },
          attrs: {
            "no-header": "",
            id: "sidebar-1",
            title: "Lagen",
            shadow: "",
          },
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _c("b-table", {
                directives: [
                  {
                    name: "b-hover",
                    rawName: "v-b-hover",
                    value: _vm.hoverHandler,
                    expression: "hoverHandler",
                  },
                ],
                ref: "layers_control_table",
                attrs: {
                  small: "",
                  "thead-class": "d-none",
                  hover: "",
                  fields: _vm.mapLayers_dataFields,
                  items: _vm.mapLayers_data,
                  "tbody-tr-class": _vm.rowClass,
                },
                on: {
                  "row-clicked": _vm.rowClicked,
                  "row-dblclicked": _vm.showModalLayerInfo,
                  "row-contextmenu": _vm.showModalLayerInfo,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(trash)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", title: "verwijder laag" },
                            on: {
                              click: function ($event) {
                                return _vm.removeLayer(data.item)
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "trash", "aria-hidden": "true" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(moveToTop)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", title: "verplaats naar top" },
                            on: {
                              click: function ($event) {
                                return _vm.moveToTop(data.item)
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "chevron-bar-up",
                                "aria-hidden": "true",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(edit)",
                    fn: function (data) {
                      return [
                        data.item.get("isEdited")
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "warning",
                                  title: "stop bewerken",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.stopEdit(data.item)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "pencil",
                                    "aria-hidden": "true",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm.currentEditedLayer == null
                                ? _c(
                                    "div",
                                    [
                                      data.item.get("isEditable")
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                size: "sm",
                                                title: "bewerk laag",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.startEdit(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "pencil-fill",
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "cell(visibility)",
                    fn: function (data) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              title: "wissel zichtbaarheid",
                            },
                            on: {
                              click: function ($event) {
                                data.item.setVisible(!data.item.getVisible())
                              },
                            },
                          },
                          [
                            data.item.getVisible()
                              ? _c("b-icon", {
                                  attrs: { icon: "eye", "aria-hidden": "true" },
                                })
                              : _c("b-icon", {
                                  attrs: {
                                    icon: "eye-slash",
                                    "aria-hidden": "true",
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(name)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          {
                            class: _vm.isHovered
                              ? ""
                              : "tableClassLayerName text-nowrap d-inline-block",
                          },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.viewResolution >
                                  data.item.getMaxResolution()
                                    ? "bluetext"
                                    : "",
                              },
                              [
                                _vm._v(
                                  "\n                                \t\t" +
                                    _vm._s(data.value) +
                                    "\n\t\t\t\t\t\t\t \t\t"
                                ),
                                data.item.get("isGettingCapabilities")
                                  ? _c("b-spinner", {
                                      attrs: {
                                        small: "",
                                        type: "grow",
                                        label: "Spinning",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                data.item.get("isGettingFeatures")
                                  ? _c("b-spinner", {
                                      attrs: {
                                        small: "",
                                        type: "grow",
                                        variant: "success",
                                        label: "Spinning",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                data.item.get("noOfTilesLoading")
                                  ? _c("b-spinner", {
                                      attrs: {
                                        small: "",
                                        type: "grow",
                                        variant: "primary",
                                        label: "Spinning",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }