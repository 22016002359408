var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-askfilelocation",
      attrs: { id: "modal-askfilelocation" },
      on: { ok: _vm.handleOk },
    },
    [
      _c("b-form-file", {
        ref: "askfilelocation",
        attrs: {
          state: Boolean(_vm.file1),
          placeholder: "Choose a file or drop it here...",
          "drop-placeholder": "Drop file here...",
        },
        on: {
          submit: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
        model: {
          value: _vm.file1,
          callback: function ($$v) {
            _vm.file1 = $$v
          },
          expression: "file1",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }, [
        _vm._v("Selected file: " + _vm._s(_vm.file1 ? _vm.file1.name : "")),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }