var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "no-fade": "",
        id: "modal-zoek-laag",
        scrollable: "",
        size: "xl",
        title: "Zoek een laag",
      },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Zoek in:" } },
            [
              _c(
                "b-form-radio",
                {
                  attrs: { inline: "", name: "target", value: "AOK" },
                  on: {
                    change: function ($event) {
                      return _vm.$refs.searchLayer_table.refresh()
                    },
                  },
                  model: {
                    value: _vm.searchTarget,
                    callback: function ($$v) {
                      _vm.searchTarget = $$v
                    },
                    expression: "searchTarget",
                  },
                },
                [_vm._v("AOK")]
              ),
              _vm._v(" "),
              _c(
                "b-form-radio",
                {
                  attrs: { inline: "", name: "target", value: "PDOK" },
                  on: {
                    change: function ($event) {
                      return _vm.$refs.searchLayer_table.refresh()
                    },
                  },
                  model: {
                    value: _vm.searchTarget,
                    callback: function ($$v) {
                      _vm.searchTarget = $$v
                    },
                    expression: "searchTarget",
                  },
                },
                [_vm._v("PDOK")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { on: { keyup: _vm.searchLayer } },
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { lg: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Filter",
                    "label-for": "filter-input",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: "trefwoord",
                          autofocus: "",
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { sm: "7", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": 10,
                  align: "fill",
                  size: "sm",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        ref: "searchLayer_table",
        attrs: {
          compact: "",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filter,
          items: _vm.myProvider,
          hover: "",
          selectable: "",
          "select-mode": "single",
          fields: _vm.tableDataFields,
        },
        on: {
          "row-dblclicked": _vm.doubleClick,
          "row-clicked": _vm.singleClick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }