<template>

	<b-modal @show="update" id="modal-sign-in" hide-footer no-fade hide-header-close v-model="isShown"  title="Aanmelden">

		<b-overlay :show="showOverlay" rounded="sm">

			<b-card>
				<b-form @submit="onSubmit" @reset="onGuest">
      					<b-form-group id="input-group-1" label="email:" label-for="input-1"  >
        					<b-form-input id="input-1" v-model="form.email" type="email" placeholder="Email" autofocus ></b-form-input>
      					</b-form-group>
      					<b-form-group id="input-group-2" label="paswoord:" label-for="input-2" >
        					<b-form-input id="input-2" v-model="form.password" type="password" placeholder="paswoord" ></b-form-input>
      					</b-form-group>
      					<b-button type="submit" variant="primary">Aanmelden</b-button>
      					<b-button type="reset" variant="danger">Gastgebruiker</b-button>
    				</b-form>
			</b-card>
			<b-card v-if='noOfPasswordAttempts > 0'>
				<b-button  @click='requestNewPassword'>Vraag nieuw paswoord</b-button>
			</b-card>

		</b-overlay >

  	</b-modal>

</template>

<script>

export default {

	data() {
		return {
			isShown				: true,
			showOverlay			: true,
			noOfPasswordAttempts: 0, 
			form: {
          			email		: '',
          			password	: ''
        	}
		}
	},

	methods: {
		onSubmit: function ( evt ) {
			evt.preventDefault();
			this.showOverlay = true;
       	 	fetch('access.php?login', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify( {
					email: 		this.form.email,
					pwd: 		this.form.password
				})
			}) 
			.then( response => response.json())
			.then( data => {
					if ( typeof data !== 'undefined' ) {
						if ( data.loggedIn == "true" ) {
							this.$root.userdata = data;
							this.isShown = false;
							this.$bvModal.msgBoxOk("Welkom terug " + data.username + ". Laatste login was: " + data.lastloggedin)
						} else {
							this.$bvModal.msgBoxOk("Onjuiste combinatie van email en paswoord. Probeer nogmaals, of vraag nieuw paswoord.")
							this.noOfPasswordAttempts = this.noOfPasswordAttempts + 1
						}
					} else {
							//
            		}
					this.showOverlay = false;
    		});
		},
		onGuest: function ( evt ) {
			evt.preventDefault();
			this.isShown = false;
		},
		update: function  ( ) {

			this.showOverlay = true;
       	 	fetch('access.php?check')
				.then( response => response.json())
				.then( data => {
					if ( typeof data !== 'undefined' ) {
						if ( data.loggedIn == "true" ) {
							this.$root.userdata = data;
							this.isShown = false;
							if ( document.dontShowModalSignin ) {
								document.dontShowModalSignin = 0; // used when returning from printview
							} else if ( ! isPrintView)  {
								this.$bvModal.msgBoxOk("Welkom terug " + data.username + ". Laatste login was: " + data.lastloggedin)
							}
						}
					} else {
							//
            		}
					this.showOverlay = false;
    		});

		},
		signOut: function () {
			this.isShown = true
       	 	fetch('access.php?logout')
			Object.assign(this.$data, this.$options.data.call(this)); //reset form data to inital values
			this.$root.userdata = null
		},
		requestNewPassword: function () {
			this.$bvModal.msgBoxOk("Not yet implemented")
		}
		

	}
}
</script>

<style>
</style>
