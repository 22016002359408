var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-list-features", title: "List Features" },
      on: { show: _vm.updateData },
    },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          fields: _vm.fields,
          items: _vm.xyzData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }