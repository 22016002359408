<template>
	<b-modal
		id		="modal-legend-graphic" 
		title	="legend graphic">

		<b-img :src="url">
			
		</b-img>

  	</b-modal>
</template>

<script>
	export default { 
		data()  {
			return { 
				url : 'https://devol5.aardeopdekaart.nl/cgi-bin/mapserv?map=maps/bodkrt.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&SCALE=3571.4214285714274&BBOX=105872.54500000001%2C467398.765%2C106141.345%2C467592.385&CRS=EPSG%3A28992&WIDTH=100&HEIGHT=100&LAYER=bodkrt'
			}
	}
}
</script>

<style>
</style>
