<template>

			<div v-if="!$root.isEditing"><!--<b-form-group id="archisgroup" v-if="isVisible">-->
				<b-button v-if			= " !isEnabled" 
        				v-shortkey.once = "['ctrl', 'r']" 
						title			= 'archis informatie [CTRL+R]'
						@shortkey		= 'toggleActive'
						@click			= "toggleActive" > 
					archis
				</b-button>
				<b-button variant="warning" v-if="isEnabled" 
        				v-shortkey.once="['ctrl', 'r']" @shortkey='toggleActive'
						@click="toggleActive" > 
					<b-spinner v-if="isFetching" small type="grow"></b-spinner>
					archis
				</b-button>
			</div> <!--</b-form-group>-->
</template>

<script>

import {DragBox} from 'ol/interaction.js';
import {bufferFeature} 			from './GIS.js';
import {cleanCloneFeature} 		from './featureFunctions.js';

export default {
	data() {
		return {
 			isVisible     	    : true,
 			isEnabled     	    : false,
 			isFetching    	    : false,
			interaction			: null
		}
	},
	methods: {
		toggleActive: function() {
			if ( this.interaction == null ) {
				this.initialize()
				console.log('archis tool initialized')
			}
			this.isEnabled = ! this.isEnabled
			this.interaction.setActive( this.isEnabled )
		},
		enable: function () { //called if end editing layer
			this.isVisible = true
		},
		disable: function () { //called if start editing layer
			if ( this.isEnabled ) {
				this.toggleActive() //set false
			}
			this.isVisible = false
		},
		initialize: function () {
			//first check if it does not already exists (printview whipes out $data)
			for( let i = 0; i < map.getInteractions().getLength(); i++ ){
				if ( map.getInteractions().item( i ).get('type') == 'ARCHIS' ){
					this.interaction = map.getInteractions().item( i )
					return
				}
			}
 			this.interaction = new DragBox () 
			this.interaction.set('type','ARCHIS')
			this.interaction.set('mydata', this.$data )
			this.interaction.setActive( false )
   			map.addInteraction( this.interaction );
			var isFetching = this.isFetching
			this.interaction.on('boxend', function() { 
				var mydata = this.get('mydata')
				mydata.isFetching = true
				var extent = this.getGeometry().getExtent();
				fetch ( 'getARCHIS.php?bbox=' + extent.join(',') )
    			.then( response => response.text())
				.then( function( html ) {
					var win=window.open('about:blank');
					if (win == null)  {
						alert('Opening new window is blocked')
					} else {
						win.document.open()
						win.document.write('<html><body>' + html +'</body></html>')
						win.document.close()
					}
					mydata.isFetching = false
				}, function ( data ) {  // failed function
					alert('The archis action aborted - please try again or report to the administrator if the problem persists.');
					mydata.isFetching = false
				})
			}) 
			let myLayers =map.getLayers()
			let length = myLayers.getLength()
			if ( 0) { //skip for now!!!
				let myLayer = myLayers.getArray()[ length -  1]
				if ( myLayer.get('type') == 'user' ) {
        			let myFeatures =  myLayer.getSource().getFeatures()
					if ( myFeatures.length > 0 ) {
						let newFeature = cleanCloneFeature ( myFeatures[0] )
						//bufferFeature( newFeature, 350 )
						let tempLayer = map.get('tempLayer')
						tempLayer.getSource().clear()
						tempLayer.getSource().addFeature( newFeature )
					}
				}
			}
			//copyFeaturesToLayer( feature, tempLayer ) 
			//bufferFeature( selectedFeature, 500 )
			//this.editedLayer.set('isModified', true );
		}
	}
}

</script>

<style> 
</style>
