<template>
 <b-modal
      id			="modal-select"
      ref			="modal-select"
      @ok			="handleOk"
	>
	<div>
    	<b-form-select v-model="selected" :options="options"></b-form-select>
    	<div class="mt-3">Metadata in 'description': <strong>{{ selected }}</strong></div>
  	</div>

</div>

   </b-modal>
</template>

<script>
  export default {
    data() {
      return {
		selected:	null,
        options: 	[ ]
      }
    },
	methods: {
		handleSubmit() {
			this.$nextTick(() => {
				this.$bvModal.hide('modal-select')
				this.resolvePromise( this.selected )
			})
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
				this.handleSubmit()
		},
		ask( text, dataItem ) {
			this.options = []
			for (const [key, value] of Object.entries( dataItem )) {
				  this.options.push( key )
			}
 			return new Promise((resolve, reject) => {
				this.$bvModal.show('modal-select')
        		this.resolvePromise = resolve;
        		this.rejectPromise = reject;
      		});
      	}
	}
}
</script>


