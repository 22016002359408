var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          staticStyle: { top: "auto" },
          attrs: { right: "", id: "featureInfo", title: "Info", shadow: "" },
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.isBusy, rounded: "sm" } }, [
            _c(
              "div",
              { staticClass: "px-3 py-2" },
              [
                _c("div", { attrs: { id: "featureInfoTop" } }),
                _vm._v(" "),
                _c("pre", [_vm._v(_vm._s(_vm.preformatted_content))]),
                _vm._v("\n\t\t\t" + _vm._s(_vm.content) + "\n\t\t\t"),
                _c("b-table", {
                  attrs: {
                    compact: "",
                    "tbody-tr-class": _vm.rowClass,
                    fields: _vm.fields,
                    "thead-class": "d-none",
                    striped: "",
                    hover: "",
                    items: _vm.items,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(value)",
                      fn: function (data) {
                        return [
                          !!data.item.url
                            ? _c(
                                "b-link",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: data.item.url,
                                  },
                                },
                                [_vm._v("link")]
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(data.value) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(html)",
                      fn: function (data) {
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(data.value) },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }