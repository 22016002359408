var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-afdrukweergave",
        title: "Wissel naar afdrukweergave",
        "hide-footer": "",
      },
      on: { ok: _vm.setPrintView },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c("p", { staticClass: "my-4" }, [
        _vm._v("Druk op [ESC] om weer naar normale weergave de wisselen."),
      ]),
      _vm._v(" "),
      _c(
        "b-form",
        { on: { submit: _vm.setPrintView } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-1",
                label: "breedte (pixels):",
                "label-for": "input-1",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-1",
                  type: "number",
                  placeholder: "breedte (pixels)",
                  autofocus: "",
                },
                model: {
                  value: _vm.width,
                  callback: function ($$v) {
                    _vm.width = $$v
                  },
                  expression: "width",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-2",
                label: "hoogte (pixels):",
                "label-for": "input-2",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-2",
                  type: "number",
                  placeholder: "hoogte (pixels)",
                },
                model: {
                  value: _vm.height,
                  callback: function ($$v) {
                    _vm.height = $$v
                  },
                  expression: "height",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-group-3",
                label: "open nieuw venster:",
                "label-for": "input-3",
              },
            },
            [
              _c("b-form-checkbox", {
                attrs: { id: "input-3" },
                model: {
                  value: _vm.openInNewWindow,
                  callback: function ($$v) {
                    _vm.openInNewWindow = $$v
                  },
                  expression: "openInNewWindow",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$root.userdata && _vm.$root.userdata.groups.includes("bva")
            ? _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-4",
                        label: "statisch plaatje:",
                        "label-for": "input-4",
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          disabled: !_vm.openInNewWindow,
                          id: "input-4",
                        },
                        model: {
                          value: _vm.staticImage,
                          callback: function ($$v) {
                            _vm.staticImage = $$v
                          },
                          expression: "staticImage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-button", { attrs: { type: "submit", variant: "primary" } }, [
            _vm._v("Afdrukweergave"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }