var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-edit-attributes",
            "hide-footer": "",
            "hide-header-close": "",
            size: "xl",
            title: "Bewerk attributen",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v("\n      \t\t\tBewerk attributen\n \t\t\t\t"),
                  _vm.feature != null
                    ? _c("b-badge", [
                        _vm._v(
                          " " + _vm._s(_vm.feature.getGeometry().getType())
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.feature != null &&
                  (_vm.feature.getGeometry().getType() == "Polygon" ||
                    _vm.feature.getGeometry().getType() == "MultiPolygon" ||
                    _vm.feature.getGeometry().getType() == "LineString" ||
                    _vm.feature.getGeometry().getType() == "MultiLineString")
                    ? _c("b-badge", [
                        _vm._v(" " + _vm._s(_vm.getFeatureSize())),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _vm._v(" "),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-1",
                    label: "Label:",
                    "label-for": "input-1",
                    description: "Nummer of korte tekst of naam",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-1",
                      maxlength: "100",
                      type: "text",
                      placeholder: "Label",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.form.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "label", $$v)
                      },
                      expression: "form.label",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-group-2",
                    label: "Omschrijving:",
                    "label-for": "input-2",
                    description: "Voer optioneel omschrijving in",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "input-2",
                      rows: "4",
                      "max-rows": "10",
                      placeholder: "Omschrijving",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.feature != null &&
              _vm.feature.getGeometry().getType() == "Point"
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-3",
                        label: "X (m RD)",
                        "label-for": "input-3",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          number: "",
                          state: _vm.validRDStatePoint,
                          id: "input-3",
                          placeholder: "X (m RD)",
                        },
                        model: {
                          value: _vm.form.X,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "X", $$v)
                          },
                          expression: "form.X",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.feature != null &&
              _vm.feature.getGeometry().getType() == "Point"
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-4",
                        label: "Y (m RD)",
                        "label-for": "input-4",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          number: "",
                          state: _vm.validRDStatePoint,
                          id: "input-4",
                          placeholder: "Y (m RD)",
                        },
                        model: {
                          value: _vm.form.Y,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "Y", $$v)
                          },
                          expression: "form.Y",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.isShortLine
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-5",
                        label: "lijn lengte (m)",
                        "label-for": "input-5",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          number: "",
                          id: "input-5",
                          placeholder: "lengte (m)",
                        },
                        model: {
                          value: _vm.form.lineLength,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "lineLength", $$v)
                          },
                          expression: "form.lineLength",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("b-button", { on: { click: _vm.editFeatureStyle } }, [
                _vm._v("Feature Stijl"),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "primary" } },
                [_vm._v("Sluit")]
              ),
              _vm._v(" "),
              _c("b-button", { attrs: { type: "reset", variant: "danger" } }, [
                _vm._v("Reset"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }