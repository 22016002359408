<template>
	<!-- ticbar horizontal -->
	<div id='barHor'  class='barHor'>
 		<div id='xtics'></div>
	</div>
</template>



<script>


import $ from "jquery";
import {Point, LineString} 	from 'ol/geom';
import Feature from 'ol/Feature';
import {Stroke, Style} from 'ol/style.js';

export default {

	data() {
		return {}
	},
	methods: {

	},
	mounted: function() {
		setHorTics()
		map.getView().on('change', setHorTics );
	},
	unmounted: function() {
		map.getView().un('change', setHorTics );
		map.get('coordRasterLayer').setMap( null ); //unmanned layer
	}
}

function setHorTics () {
		if ( ! map.get('app').isPrintView ) {
			//unmounted is not called? 
			map.getView().un('change', setHorTics );
			map.get('coordRasterLayer').setMap( null ); //unmanned layer
		}
		$("#xtics").empty()
		let rotation = map.getView().getRotation()

		let mapWidth = $("#map").width();
		let extent = map.getView().calculateExtent( map.getSize());
		let xRight = extent[2];
		let xLeft = extent[0];
		let yBottom = extent[1]
		let yTop = extent[3]
		let xDiff= xRight - xLeft;
		let xScale= mapWidth / xDiff;

		let ticXDiff = calcTicDiff(  xRight, xLeft ) 
		let ticYDiff = calcTicDiff( yTop, yBottom )
		let x = Math.floor((xLeft / ticXDiff)) * ticXDiff;
		let y = Math.floor(yBottom / ticYDiff ) * ticYDiff 
		let y0 = y; //save the values
		let x0 = x; //save the values

		let source = map.get('coordRasterLayer').getSource()
		let res = map.getView().getResolution() //resolution = meters per pixel

		if ( rotation != 0 ) {
			//Tics don't work, use grid instead
			map.get('coordRasterLayer').setMap( map ); //unmanned layer
			source.clear() //make it clean

			while (x < xRight) {
					let p0 = [ x, yBottom ]
					let p1 = [ x, yTop ]
					let line = new LineString([ p0, p1 ] )
					let feature = new Feature ( { 'the_geom': line }) 
					feature.set('label', x.toString() )
					feature.setGeometryName('the_geom')
					source.addFeature( feature )
					x += ticXDiff;
			}
			while (y < yTop) {
					let p0 = [ xLeft, y ]
					let p1 = [ xRight, y ]
					let line = new LineString([ p0, p1 ] )
					let feature = new Feature ( { 'the_geom': line }) 
					feature.setGeometryName('the_geom')
					feature.set('label', y.toString() )
					source.addFeature( feature )
					y += ticYDiff;
			}
			return

		} else {
			//clear and redraw and draw Coordinate Crosses map.get('coordRasterLayer').getSource().refresh()
			//let source = map.get('coordRasterLayer').getSource()
			map.get('coordRasterLayer').setMap( map ); //unmanned layer
			source.clear() //make it clean

			while (x < xRight) {
					//let p0 = [ x, yBottom ]
					//let p1 = [ x, yTop ]
					//let line = new LineString([ p0, p1 ] )
					//let feature = new Feature ( { 'the_geom': line }) 
					//feature.set('label', x.toString() )
					//feature.setGeometryName('the_geom')
					//source.addFeature( feature )
					y = y0
					while (y < yTop) {
						let p0 = [ x, y - 5*res ]
						let p1 = [ x, y + 5*res ]
						let line1 = new LineString([ p0, p1 ] )
						let feature1 = new Feature ( { 'the_geom': line1 }) 
						feature1.setGeometryName('the_geom')
						feature1.setStyle( new Style({ stroke: new Stroke({ color: 'grey', }) }) )
						let p2 = [ x - 5*res, y ]
						let p3 = [ x + 5*res, y ]
						let line2 = new LineString([ p2, p3 ] )
						let feature2 = new Feature ( { 'the_geom': line2 }) 
						feature2.setGeometryName('the_geom')
						feature2.setStyle( new Style({ stroke: new Stroke({ color: 'grey', }) }) )
						//feature.set('label', y.toString() )
						source.addFeature( feature1 )
						source.addFeature( feature2 )
						y += ticYDiff;
					}
					x += ticXDiff;
			}
		}
		x = x0;

		var pow = 0;
		var step = 1;
		let xPX
		let i = 0
		while (x < xRight) {
			xPX = (x - xLeft) * xScale;

			if (x >= xLeft) {

				//let goodPixel = map.getPixelFromCoordinate([x,400000]);
				let p0 = [ x, yBottom - 20*res ]
				let p1 = [ x, yBottom + 20*res ]
				let line2 = new LineString([ p0, p1 ] )
				let feature2 = new Feature ( { 'the_geom': line2 }) 
				feature2.setGeometryName('the_geom')
				//source.addFeature( feature2 ) // needed to pixel tune alignment of xtic (and ytic) lines

				$("#xtics").append("<div class='xticline' style='left:"+xPX+"px;'  '/>");
				$("#xtics").append("<div class='tictext xtictext' id='xticlabel"+i+"'style='left:"+xPX+"px' >"+x+"</div>");
				let textWidth = $("#xticlabel"+i).width();
				if (xPX + textWidth > mapWidth) {
					$("#xticlabel" + i).remove();
				} 
			}
			x += ticXDiff;
			i++;
		}

}

function calcTicDiff( max, min) {
	let diff = max - min;
	let pow = 0;
	let step = 1;
	while ((diff / (Math.pow(10,pow) * step)) > 7) {
		if (step == 1) {
			step = 2;
		} else if (step == 2) {
			step = 4;
		} else if (step == 4) {
			step = 5;
		} else if (step == 5) {
			step = 1;
			pow = pow + 1;
		}
	}
	return Math.pow(10,pow) * step;
}

</script>

<style>
</style>
