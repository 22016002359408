<template>
 <b-modal
      id			="modal-askfilelocation"
      ref			="modal-askfilelocation"
      @ok			="handleOk"
	>
    <!-- Styled -->
    <b-form-file @submit.stop.prevent="handleSubmit"
	  ref 				= "askfilelocation"
      v-model			= "file1"
      :state			= "Boolean(file1)"
      placeholder		= "Choose a file or drop it here..."
      drop-placeholder	= "Drop file here..."
    >
	</b-form-file>
    <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div>

</div>

   </b-modal>
</template>

<script>
  export default {
    data() {
      return {
        file1: 		null,
      }
    },
	methods: {
		handleSubmit() {
			this.$nextTick(() => {
				this.$bvModal.hide('modal-askfilelocation')
				this.resolvePromise( { 'file': this.file1 } )
			})
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
				this.handleSubmit()
		},
		ask( text ) {
 			return new Promise((resolve, reject) => {
				this.$bvModal.show('modal-askfilelocation')
        		this.resolvePromise = resolve;
        		this.rejectPromise = reject;
      		});
      	}
	}
}
</script>


