var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-edit-layer", size: "lg" },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _vm._v(
                "\n\t\t\tGegevens van de " +
                  _vm._s(_vm.title) +
                  " (" +
                  _vm._s(_vm.form.action) +
                  ")\n    \t"
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _vm._v(" "),
      _c(
        "b-overlay",
        { attrs: { show: _vm.showOverlay, rounded: "sm" } },
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "3",
                    id: "input-group-name",
                    label: "naam",
                    "label-for": "input-name",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-name",
                      state: _vm.validateState("newName"),
                      "aria-describedby": "input-live-feedback",
                      maxlength: "200",
                      type: "text",
                      placeholder: "laagnaam",
                      trim: "",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.$v.form.newName.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.newName, "$model", $$v)
                      },
                      expression: "$v.form.newName.$model",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { id: "input-live-feedback" } },
                    [
                      _vm._v(
                        "Enter 3 up to 200 letters, must be unique for each user"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.$v.form.newName.isUnique
                    ? _c("b-form-invalid-feedback", [
                        _vm._v("Layername is already registered"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "3",
                    id: "input-group-access",
                    label: "access",
                    "label-for": "input-access",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "input-access", options: _vm.accessOptions },
                    model: {
                      value: _vm.form.access,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "access", $$v)
                      },
                      expression: "form.access",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "3",
                    id: "input-group-groups",
                    label: "groepen",
                    "label-for": "input-groups",
                  },
                },
                [
                  _c("b-form-checkbox-group", {
                    attrs: {
                      id: "input-groups",
                      options: _vm.groupOptions,
                      name: "groups",
                    },
                    model: {
                      value: _vm.form.newGroup,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newGroup", $$v)
                      },
                      expression: "form.newGroup",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.type == "image"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            label: "url:",
                            "label-for": "input-url",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "url",
                              trim: "",
                              state: _vm.validateState("url"),
                              "aria-describedby": "url-fb",
                              id: "input-url",
                            },
                            model: {
                              value: _vm.$v.form.url.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.url, "$model", $$v)
                              },
                              expression: "$v.form.url.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "url-fb" } },
                            [_vm._v("Enter at valid url")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            number: "",
                            label: "x-min:",
                            "label-for": "input-leftextent",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: _vm.validateState("leftextent"),
                              "aria-describedby": "leftextent-fb",
                              id: "input-leftextent",
                            },
                            model: {
                              value: _vm.$v.form.leftextent.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.leftextent, "$model", $$v)
                              },
                              expression: "$v.form.leftextent.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "leftextent-fb" } },
                            [
                              _vm._v(
                                "x-min must be between 647 and x-max (EPSG:29882)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            number: "",
                            label: "y-min:",
                            "label-for": "input-bottomextent",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: _vm.validateState("bottomextent"),
                              "aria-describedby": "bottomextent-fb",
                              id: "input-bottomextent",
                            },
                            model: {
                              value: _vm.$v.form.bottomextent.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.bottomextent,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.form.bottomextent.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "bottomextent-fb" } },
                            [
                              _vm._v(
                                "Y must be between 276051 and 636456 (EPSG:29882)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            number: "",
                            label: "x-max:",
                            "label-for": "input-rightextent",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: _vm.validateState("rightextent"),
                              "aria-describedby": "righttextent-fb",
                              id: "input-rightextent",
                            },
                            model: {
                              value: _vm.$v.form.rightextent.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.rightextent, "$model", $$v)
                              },
                              expression: "$v.form.rightextent.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "rightextent-fb" } },
                            [
                              _vm._v(
                                "x-max must be between x-min and 308975 (EPSG:29882)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            number: "",
                            label: "y-max:",
                            "label-for": "input-topextent",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: _vm.validateState("topextent"),
                              "aria-describedby": "toptextent-fb",
                              id: "input-topextent",
                            },
                            model: {
                              value: _vm.$v.form.topextent.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.topextent, "$model", $$v)
                              },
                              expression: "$v.form.topextent.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "topextent-fb" } },
                            [
                              _vm._v(
                                "Y must be between 276051 and 636456 (EPSG:29882)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols": "3",
                            number: "",
                            label: "rotation:",
                            "label-for": "input-rotation",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              state: _vm.validateState("rotation"),
                              "aria-describedby": "rotation-fb",
                              id: "input-rotation",
                            },
                            model: {
                              value: _vm.$v.form.rotation.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.rotation, "$model", $$v)
                              },
                              expression: "$v.form.rotation.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "rotation-fb" } },
                            [
                              _vm._v(
                                "Rotatie must be between -180 and 180 degrees"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-cols": "3",
                    label: "Omschrijving:",
                    "label-for": "input-description",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "input-description",
                      maxlength: "500",
                      rows: "4",
                      "max-rows": "10",
                      placeholder: "Omschrijving",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }