<template>
  <div>
	<b-modal id="modal-edit-attributes" hide-footer hide-header-close size="xl" v-model="isShown"  title="Bewerk attributen">
			<template #modal-title>
      			Bewerk attributen
 				<b-badge v-if="feature != null"> {{ feature.getGeometry().getType() }}</b-badge>
 				<b-badge v-if="feature != null && (feature.getGeometry().getType() == 'Polygon' || feature.getGeometry().getType() == 'MultiPolygon' || feature.getGeometry().getType() == 'LineString' || feature.getGeometry().getType() == 'MultiLineString') "> {{ getFeatureSize() }}</b-badge>
    		</template>
    	<b-form @submit="onSubmit" @reset="onReset">
      	<b-form-group id="input-group-1" label="Label:" label-for="input-1" description="Nummer of korte tekst of naam" >
        	<b-form-input id="input-1" v-model="form.label" maxlength="100" type="text" placeholder="Label" autofocus ></b-form-input>
      	</b-form-group>
      	<b-form-group id="input-group-2" label="Omschrijving:" label-for="input-2" description="Voer optioneel omschrijving in">
        	<b-form-textarea id="input-2" v-model="form.description" rows="4"  max-rows="10" placeholder="Omschrijving" ></b-form-textarea>
      	</b-form-group>
      	<b-form-group v-if="feature != null && feature.getGeometry().getType() == 'Point'" id="input-group-3" label="X (m RD)" label-for="input-3">
        		<b-form-input number :state="validRDStatePoint" id="input-3" v-model="form.X" placeholder="X (m RD)" ></b-form-input>
      	</b-form-group>
      	<b-form-group v-if="feature != null && feature.getGeometry().getType() == 'Point'" id="input-group-4" label="Y (m RD)" label-for="input-4">
        		<b-form-input number :state="validRDStatePoint" id="input-4" v-model="form.Y" placeholder="Y (m RD)" ></b-form-input>
      	</b-form-group>
      	<b-form-group v-if=" form.isShortLine " id="input-group-5" label="lijn lengte (m)" label-for="input-5">
        		<b-form-input number id="input-5" v-model="form.lineLength" placeholder="lengte (m)" ></b-form-input>
      	</b-form-group>
      	<b-button @click="editFeatureStyle">Feature Stijl</b-button>
		<hr/>
      	<b-button type="submit" variant="primary">Sluit</b-button>
      	<b-button type="reset" variant="danger">Reset</b-button>
    	</b-form>
  	</b-modal>
  </div>
</template>


<script>

import {setFeatureStateUpdate} 	from './globals.js';

export default {
	computed: {
		validRDStatePoint() {
			if (typeof this.form.X  === 'number'  &&
					typeof this.form.Y  === 'number' &&
					this.form.X < this.form.Y ) {
			return true;
			} else {
				return false;
			}
		}
	},
	data() {
		return {
			isShown				: false,
			form: {
          			label		: '',
          			description	: '',
          			X			: 200000,
          			Y			: 400000,
					isShortLine : false,
          			lineLength	: 100
        	},
			featureCollection	: {},
			feature				: null
		}
	},
	methods: {
		edit: function ( feature, featureCollection ) {
			this.isShown = true;
			this.feature = feature;
			let props = feature.getProperties();
			this.form.label 	   = props.label 	  || '';
			this.form.description  = props.description || '';
			this.featureCollection = featureCollection;

			let geometry = feature.getGeometry();
			let coords = geometry.getCoordinates();
			if (geometry.getType() == 'Point') {
				this.form.X = coords[0];
				this.form.Y = coords[1];
			} else if (geometry.getType() == 'LineString') {
				if (coords.length == 2) {
					this.form.isShortLine = true;
					this.form.lineLength = geometry.getLength();
				}
			}
		},
		onSubmit: function ( evt ) {
			evt.preventDefault();
			this.feature.setProperties( { 
				label		: 	this.form.label, 
				description : 	this.form.description 
			} );
			let geometry = this.feature.getGeometry();
    		if ( geometry.getType() == "Point") {
				let X = this.form.X;
				let Y = this.form.Y;
				if (typeof X === 'number' && typeof Y === 'number') {
					geometry.setCoordinates([X,Y ] ,'XY');
				}
			}
    		else if ( this.form.isShortLine ) {
				let coords = geometry.getCoordinates();
				let oldLength = geometry.getLength();
				let newLength = this.form.lineLength;
				let startP = coords[0];
				let endP = coords[1];
				endP[0] = startP[0] + (endP[0] - startP[0]) * newLength / oldLength;
				endP[1] = startP[1] + (endP[1] - startP[1]) * newLength / oldLength;
				geometry.setCoordinates([ [startP[0],startP[1]], [endP[0],endP[1]] ] ,'XY');
			}
			setFeatureStateUpdate( this.feature )
			this.isShown = false;
			if (this.featureCollection) {
				this.featureCollection.clear();//clear selection
			}
		},
		onReset: function ( event ) {
			this.form.label = "";
			this.form.description = "";
			this.feature.set('style',null)
		},
		getFeatureSize: function () {
    		var sizeInfo = "";
    		var geometry = this.feature.getGeometry();
    		if ( geometry.getType() == "Polygon") {
        		var area = geometry.getArea();
        		var units = "m2";
        		if (area > 10000) { area /= 10000; units = "ha";}
        		sizeInfo += "area ( " + area + units + ")";
    		} else if ( geometry.getType() == "MultiPolygon") {
				let area = 0
				for( let i = 0; i < geometry.getPolygons().length; i ++){
					area += geometry.getPolygon(i).getArea()
				}
        		var units = "m2";
        		if (area > 10000) { area /= 10000; units = "ha";}
        		sizeInfo += "area ( " + area + units + ")";
    		} else if ( geometry.getType() ==  "LineString") {
        		var length = geometry.getLength();
        		var units = "m";
        		if (length > 1000) { length /= 1000; units = "km";}
        		sizeInfo += "length: " + length + units;
    		} else if ( geometry.getType() ==  "MultiLineString") {
				let length = 0
				for( let i = 0; i < geometry.getLineStrings().length; i ++){
					length += geometry.getLineString(i).getLength()
				}
        		var units = "m";
        		if (length > 1000) { length /= 1000; units = "km";}
        		sizeInfo += "length: " + length + units;
			}
    		return sizeInfo;
		},
		editFeatureStyle: function() {
			this.$root.$refs.modalStyling.show( this.feature, 'featureMode' )
		}
	}
}
</script>

<style>
</style>
