<template>
	<!-- transact modal -->
	<b-modal id="modal-transact" title="Transact" v-model="isShown" ok-only>
			<b-table  
					compact
				    thead-class			="d-none"
					:fields				="tableDataFields"
                    :items				="tableData" >
            </b-table>
		
  	</b-modal>
	<!-- END transact modal -->
</template>
<script>

export default {
	data() {
		return {
			isShown				: false,
			tableData			: [],
			tableDataFields		: ["item","count" ]
		}
	},
	methods: {
		showResult: function ( transactResults ) {
			let keys = Object.keys(transactResults.transactionSummary);
			this.tableData.push( 
				{ 	"item"	: "result:", 
					"count"	: ""
				});
			for (let key of keys ) {
				this.tableData.push( 
					{ 	"item"	: key, 
						"count"	: transactResults.transactionSummary[ key ] 
					} );
			}
		},
		start: function () {
			this.tableData.push( 
				{ 	"item"	: "sending data", 
					"count"	: ""
				});
		},
		close: function () {
			this.isShown = false;
		},
		open: function () {
			this.tableData = [];
			this.isShown = true;
		}
	
	}
}
</script>

<style>
</style>
