'use strict' 

import {catchJSONParse} from './globals.js';
import {FeatureStates} 	from './globals.js';

import * as olColor from 'ol/color';
import {RegularShape, Circle, Style, Fill, Stroke, Text} from 'ol/style';
import GeometryCollection                   				from 'ol/geom/GeometryCollection';
import MultiPoint                   						from 'ol/geom/MultiPoint';
import {calculateCenter}                                    from './GIS.js';


export function getViewStyleFunction ( layer ) {
	var aokLayer = layer;
	return function ( feature ) {
		if ( feature.get( 'state' ) == FeatureStates.DELETE ) {
			return new Style({});
		} else {
			let layerStyleProperties = aokLayer.get('style') || {}
			let featureStyleProperties = catchJSONParse( feature.get('style') )
			featureStyleProperties['isLabeled'] = layerStyleProperties['isLabeled'] ;
			let featureStyle = {}
			for( const key in layerStyleProperties ) {
  				featureStyle[ key ] = (featureStyleProperties[ key ] === undefined || featureStyleProperties[key] == null) ? layerStyleProperties[ key ] : featureStyleProperties[key]
			}
			
    		var stroke;

			var fillColor =  olColor.asArray( featureStyle.fillColor )
			fillColor[3] = featureStyle.fillOpacity
			var fill = featureStyle.showFills ? new Fill({ color: fillColor }) : null ;

			if ( featureStyle.showStrokes ) {
    			stroke = new Stroke({ 
					color:    featureStyle.strokeColor,
					lineDash: layerStyleProperties.DASH[ featureStyle.strokeDashstyle ], 
					width: 	  featureStyle.strokeWidth 
				})
			} else {
    			stroke = null;
			}

			if ( featureStyle.patternHorStripes ) {
				var canvas=document.createElement('canvas')
               	var context=canvas.getContext('2d')
				canvas.width = 8
				canvas.height = 8
				context.globalAlpha = featureStyle.fillOpacity
				context.fillStyle = featureStyle.fillColor
				if ( featureStyle.patternHorStripesDashed ) {
					context.fillRect(0, 0, canvas.width - 4, 2)
				} else {
					context.fillRect(0, 0, canvas.width, 2)
				}
 				var pattern = context.createPattern(canvas, 'repeat');
				fill.setColor( pattern );
			} 

			var labelText = feature.get( featureStyle.labelField) ;
			let type = typeof labelText
			if ( type == "number" ) {
				labelText = String( labelText )
			}
			labelText = typeof labelText == "string" ? labelText: "";
			if ( featureStyle.isANumber ) {//rounds to one decimal
				let number = parseFloat( labelText )
				labelText = ( Math.round( number * 10 ) / 10).toString() 
			}
  			var text = featureStyle.isLabeled ? new Text({
                        font: 		featureStyle.labelFontSize + 'px helvetica,sans-serif',
                        text: 		labelText,
                        placement:	featureStyle.placement,
						//offsetY: 	featureStyle.textAlign == 'left' ? featureStyle.labelYOffset : -1 * featureStyle.labelYOffset,
						offsetY: 	featureStyle.labelYOffset,
        				offsetX: 	featureStyle.textAlign == 'left' ? featureStyle.labelXOffset : -1 * featureStyle.labelXOffset,
						textAlign:	featureStyle.textAlign,
                        rotation: 	0,
                        fill: 		new Fill({ color: featureStyle.labelColor }),
                        stroke: 	new Stroke({ color: '#fff', width: 2 }),
						overflow: 	true
                    }) : null;

				
			let pointType = featureStyle.pointType
			let image = null
			let image2 = null
			if ( pointType == "cross" ) {
				image = new RegularShape({ fill: fill, stroke: stroke, points: 4, radius: featureStyle.pointRadius, radius2: 0, angle: 0, angle: Math.PI / 4 })
			} else if ( pointType == "triangle" ) {
				image = new RegularShape({ fill: fill, stroke: stroke, points: 3, radius: featureStyle.pointRadius, rotation: Math.PI / 4, angle: 0, })
			} else if ( pointType == "square" ) {
				image = new RegularShape({ fill: fill, stroke: stroke, points: 4, radius: featureStyle.pointRadius, angle: Math.PI / 4, })
			} else if ( pointType == "arrow" ) {
 				let shaft =new RegularShape({ points: 2, radius: 5, stroke: new Stroke({ width: featureStyle.strokeWidth, color: featureStyle.strokeColor }), rotateWithView: true });
				let head = new RegularShape({ points: 3, radius: 5, fill: new Fill({ color: featureStyle.strokeColor }), rotateWithView: true, });
				let angle = featureStyle.pointAngle / 360 * 2 * 3.1415972
				let scale = featureStyle.pointRadius
 				shaft.setScale([1, scale]);
				shaft.setRotation(angle)
				head.setDisplacement([ 0, head.getRadius() / 2 + shaft.getRadius() * scale, ])
        		head.setRotation(angle)
				return [new Style({
          			stroke: stroke,
					text: 	text,
          			fill: 	fill,
          			image: 	shaft }), new Style({image: head}) ];
			} else {
				image = new Circle({ radius: featureStyle.pointRadius * 1.0, fill: fill, stroke: stroke })
			}
			return new Style({
          		stroke: stroke,
				text: 	text,
          		fill: 	fill,
          		image: 	image });
		}
	}
}

export function getEditStyleFunction ( layer ) {
	var aokLayer = layer;
	return function ( feature ) {
		if ( feature.get( 'state' ) == FeatureStates.DELETE ) return new Style({});
		let layerStyleProperties = aokLayer.get( 'style' )
		var text = null;
		var color =  olColor.asArray( 'red'  ) ;
		color[3] = 0.5;
		var fill = new Fill({ color: color });
    	var stroke = new Stroke({	
			color		: 'blue', 
			lineDash	: layerStyleProperties.DASH[ 'solid' ], 
			width		: 2 
		});
		var image = new Circle({ 
				radius : 5, 
				fill   : null, 
				stroke: stroke
		});
		if ( feature.getKeys().includes( layerStyleProperties.labelField )) {
			var labelText = feature.get( layerStyleProperties.labelField );
			labelText = typeof labelText == "string" ? labelText: "";
  			text = new Text({
                        font		: '12px helvetica,sans-serif',
                        text		: labelText,
						offsetY		: layerStyleProperties.labelYOffset,
        				offsetX		: layerStyleProperties.labelXOffset,
                        rotation	: 0,
                        fill: 		new Fill({ color: 'blue' }),
                        stroke: 	new Stroke({ color: '#fff', width: 2 })
			});
		}
		return new Style({ stroke: stroke, text: text, fill: fill, image: image });
	}
}

export function getDrawStyleFunction (  ) {
	return function ( feature ) {
		var text = null;
		var color =  olColor.asArray( 'grey'  ) ;
		color[3] = 0.5;
		var fill = new Fill({ color: color });
    	var stroke = new Stroke({	
			color		: 'purple', 
			lineDash	: [10, 10],
			width		: 1 
		});
		var image = new Circle({ 
				radius : 5, 
				fill   : null, 
				stroke: stroke
		});
		var geometryFunction = function(feature) {
			let geometry = feature.getGeometry();
			if ( geometry.getType() == "Point" ) {
      			return  geometry;
			} else {
				let coordinates = geometry.getCoordinates();
				if ( geometry.getType() == "LineString" ) {
      				return  new GeometryCollection( [ geometry, new MultiPoint( coordinates ) ]);
				} else if ( geometry.getType() == "Polygon" ) {
      				return  new GeometryCollection( [ geometry, new MultiPoint( coordinates[0] ) ]);
				} 
      			return geometry; //fall back
			}
    	}
		return new Style({ geometry: geometryFunction, stroke: stroke, text: text, fill: fill, image: image })
	}
}
export function getModifyStyleFunction ( layer ) {
	var aokLayer = layer;
	return function ( feature ) {
		if ( feature.get( 'state' ) == FeatureStates.DELETE ) return new Style({});
		let layerStyleProperties = aokLayer.get( 'style' )
		var text = null;
		var color =  olColor.asArray( 'grey'  ) ;
		color[3] = 0.5;
		var fill = new Fill({ color: color });
    	var stroke = new Stroke({	
			color		: 'purple', 
			lineDash	: layerStyleProperties.DASH[ 'solid' ], 
			width		: 1 
		});
		var image = new Circle({ 
				radius : 5, 
				fill   : null, 
				stroke: stroke
		});
		//if ( feature.getKeys().includes( layerStyleProperties.labelField ) ) {
			//var labelText = feature.get( layerStyleProperties.labelField );
			//labelText = typeof labelText == "string" ? labelText: "";
  			//text = new Text({
                        //font		: '12px helvetica,sans-serif',
                        //text		: feature.get( layerStyleProperties.labelField ),
						//offsetY		: layerStyleProperties.labelYOffset,
        				//offsetX		: layerStyleProperties.labelXOffset,
                        //rotation	: 0,
                        //fill: 		new Fill({ color: 'blue' }),
                        //stroke: 	new Stroke({ color: '#fff', width: 2 })
                    //});
		//}
		text = '';
		var geometryFunction = function(feature) {
			let geometry = feature.getGeometry();
			if ( geometry.getType() == "Point" ) {
      			return  geometry;
			} else {
				let coordinates = geometry.getCoordinates();
				if ( geometry.getType() == "LineString" ) {
      				return  new GeometryCollection( [ geometry, new MultiPoint( coordinates ) ]);
				} else if ( geometry.getType() == "Polygon" ) {
      				return  new GeometryCollection( [ geometry, new MultiPoint( coordinates[0] ) ]);
				} 
      			return geometry; //fall back
			}
    	}
		return new Style({ geometry: geometryFunction, stroke: stroke, text: text, fill: fill, image: image })
	}
}

	/*return function ( feature ) {
		let fill = new Fill({ color: 'rgba(255, 255, 255, 0.2)', })
  		let stroke = new Stroke({ color: '#ffcc33', width: 2 })
  		let geometry = function (feature) {
    				const modifyGeometry = feature.get('modifyGeometry');
    				return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
 		}
		let  image = new Circle({ 
				radius : 5, 
				fill   : null, 
				stroke: stroke
		})
		return new Style({ geometry: geometry, stroke: stroke, fill: fill, image: image })
	}
}*/

export function getRotate_StyleFunction ( feature ) {
	return [
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'red'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		angle: 			0,
        		displacement: 	[0, 8]
      		})
    	}),
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'blue'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		displacement: 	[8, 0],
        		angle: 			Math.PI / 2
      		})
    	}),
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'blue'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		displacement: 	[-8, 0],
        		angle: 			Math.PI / 2
      		})
    	})
  ]
}

export function getModify_StyleFunction ( feature ) {
	return [
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'red'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		angle: 			0,
        		displacement: 	[0, 8]
      		})
    	}),
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'blue'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		displacement: 	[8, 0],
        		angle: 			Math.PI / 2
      		})
    	}),
    	new Style({
      		image: new RegularShape({
        		fill: 			new Fill({color: 'blue'}),
        		stroke: 		new Stroke({color: 'black', width: 1}),
        		points: 		2,
        		radius: 		5,
        		displacement: 	[-8, 0],
        		angle: 			Math.PI / 2
      		})
    	})
  ]
}


export function getSelectStyleFunction ( layer ) {
	var aokLayer = layer;
	return function ( feature ) {
		if ( feature.get( 'state' ) == FeatureStates.DELETE ) return new Style({});
		let layerStyleProperties = aokLayer.get( 'style' )
		var text = null;
		var color =  olColor.asArray( 'grey'  ) ;
		color[3] = 0.5;
		var fill = new Fill({ color: color });
    	var stroke = new Stroke({	
			color		: 'purple', 
			lineDash	: layerStyleProperties.DASH[ 'solid' ], 
			width		: 1 
		});
		var image = new Circle({ 
				radius : 5, 
				fill   : null, 
				stroke: stroke
		});
		if ( feature.getKeys().includes( layerStyleProperties.labelField ) ) {
			var labelText = feature.get( layerStyleProperties.labelField);
			labelText = typeof labelText == "string" ? labelText: "";
  			text = new Text({
                        font		: '12px helvetica,sans-serif',
                        text		: labelText,
						offsetY		: layerStyleProperties.labelYOffset,
        				offsetX		: layerStyleProperties.labelXOffset,
                        rotation	: 0,
                        fill		: new Fill({ color: 'blue' }),
                        stroke		: new Stroke({ color: '#fff', width: 2 })
                    });
		}
		return new Style({ stroke: stroke, text: text, fill: fill, image: image })
	}
}


export function getDefaultVectorStyle( layerData ) {
	if ( ! layerData ) {
		layerData = {}
	}
	if ( ! layerData.style ) {
		layerData.style = {}
	}
	let style = {
		'strokeColor'			: layerData.style.strokeColor 			|| 'black', 
		'showStrokes'			: layerData.style.showStrokes 			|| true,
		'showPoints'			: layerData.style.showPoints 			|| true,
		'strokeDashstyle'		: layerData.style.strokeDashstyle   	|| 'solid',
		'strokeWidth'			: layerData.style.strokeWidth       	||   2,
		'pointRadius'			: layerData.style.pointRadius       	||   2,
		'pointAngle'			: layerData.style.pointAngle        	||   0,
		'pointType'				: layerData.style.pointType 	      	|| 'circle',
		'showFills'				: layerData.style.showFills         	|| false,
		'patternHorStripes'		: layerData.style.patternHorStripes 	|| false,
		'patternHorStripesDashed'		: layerData.style.patternHorStripesDashed 	|| false,
		'fillColor'				: layerData.style.fillColor         	|| 'grey',
		'fillOpacity'			: layerData.style.fillOpacity       	||   0.5,
		'layerOpacity'			: layerData.style.layerOpacity      	||   1,
		'labelColor'			: layerData.style.labelColor        	|| 'black',
		'labelXOffset'			: layerData.style.labelXOffset      	||   7,
		'labelYOffset'			: layerData.style.labelYOffset      	|| -10,
		'labelOutlineColor'		: layerData.style.labelOutlineColor 	|| 'white',
		'labelOutlineWidth'		: layerData.style.labelOutlineWidth 	||   2,
		'labelOutlineOpacity'	: layerData.style.labelOutlineOpacity 	||   1,
		'labelFontSize'			: layerData.style.labelFontSize 		||   12,
		'isLabeled'				: layerData.style.isLabeled           	|| false,
		'isANumber'				: layerData.style.isANumber           	|| false,
		'labelField'			: layerData.style.labelField         	|| 'label',
		'placement'				: layerData.style.placement         	|| 'point',
		'textAlign'				: layerData.style.textAlign 	       	|| 'left',
		'DASH'					: { 'solid' : undefined, 'dash': [10,10], 'dashdot': [15,4,1,4], 'longdash' : [20,10], 'longdashdot' : [20, 4, 1, 4] }
	}
	return style
}

export function setDefaultVectorStyle( vectorLayer, layerData ) {
	let style = getDefaultVectorStyle( layerData ) 
	vectorLayer.set('style', style) 
	vectorLayer.set('isEditable', layerData.isEditable)
	vectorLayer.set('isSaveable', layerData.isSaveable)
	vectorLayer.set('isEdited', layerData.isEdited)

	vectorLayer.set('startEdit', function() {
		vectorLayer.set('isEdited', true);
    	vectorLayer.setStyle( getEditStyleFunction( vectorLayer ));
	} );

	vectorLayer.set('stopEdit', function() {
		vectorLayer.set('isEdited', false);
		vectorLayer.set('isModified', false);
		if ( vectorLayer.get('isSaveable') ) {
			vectorLayer.getSource().refresh();
		}
    	vectorLayer.setStyle( getViewStyleFunction( vectorLayer ));
	} );
    vectorLayer.setStyle( getViewStyleFunction( vectorLayer ));
}
