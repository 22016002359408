var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-select",
      attrs: { id: "modal-select" },
      on: { ok: _vm.handleOk },
    },
    [
      _c(
        "div",
        [
          _c("b-form-select", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3" }, [
            _vm._v("Metadata in 'description': "),
            _c("strong", [_vm._v(_vm._s(_vm.selected))]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }