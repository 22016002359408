'use strict' ;


export var boundsNL = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];

export var resolutionsNL =  [3440.64, 1720.32, 860.16, 430.08, 215.04, 107.52, 53.76, 26.88, 13.44, 6.72, 3.36, 1.68, 0.84, 0.42, 0.21, 0.105, 0.0525, 0.02525];

export var projectionNL = 'EPSG:28992';

export var FeatureStates =  {
            INSERT : "insert",
            UPDATE : "update",
            DELETE : "delete",
            UNDEFINED : "undefined"
}

export function setFeatureStateInsert ( feature ) {
	if ( typeof( feature.get('state')) == FeatureStates.UNDEFINED ) {
		feature.set( 'state', 'insert' ); //this should be the default behaviour, it happens when new feature is created
	} else if ( feature.get('state') == FeatureStates.INSERT ) {
		alert("Fatal error; Requested to set state insert on feature that already has insert state. Please inform the administrator and reload the app.");
	} else if ( feature.get('state') == FeatureStates.UPDATE  ) {
		alert("Fatal error; Request to set state insert on feature that has state update. Please inform the administrator and reload the app.");
	} else if ( feature.get('state') == FeatureStates.DELETE ) {
		alert("Fatal error; Request to set state Insert on feature that has state Delete. It cannot be inserted. Please inform the administrator and reload the app.");
	} 
}

export function setFeatureStateDelete ( feature ) {
	if ( typeof( feature.get('state')) == 	FeatureStates.UNDEFINED  ) {
		feature.set( 'state', 'delete' ); //this happens if a layer is loaded, and features are deleted.
	} else if ( feature.get('state') == 	FeatureStates.INSERT  ) {
		alert("Fatal error; Request to delete feature in DB that was listed to be inserted. Please inform the administrator and reload the app.");
	} else if ( feature.get('state') == 	FeatureStates.UPDATE ) {
		feature.set( 'state', 'delete' ); //this happens if a layer is loaded, a feature is changed, but now deleted
	} else if ( feature.get('state') == 	FeatureStates.DELETE ) {
		alert("Fatal error; Request to set state delete on feature that has state delete. It cannot be inserted. Please inform the administrator and reload the app.");
	} 
}


export function setFeatureStateUpdate ( feature ) {
	if ( typeof( feature.get('state') ) == FeatureStates.UNDEFINED ) {
		feature.set( 'state', 'update' ); //this happens if it is loaded, and updated in the GUI
	} else if ( feature.get('state') == FeatureStates.INSERT ) {
		//leave it as it is					//this happens if it is created, but not saved, and updated in the GUI
	} else if ( feature.get('state') == FeatureStates.UPDATE ) {
		//leave it as it is					//this hppens if it is loaded, updated and not saved, and updated once more
	} else if ( feature.get('state') == FeatureStates.DELETE ) {
		alert("Fatal error; Request Update state on feature that has Delete state; Please inform the administrator and reload the app.");
	} 
}

export function setFeaturesStateUpdate ( features ) {
	for( let i = 0; i < features.length; i ++ ) {
		setFeatureStateUpdate ( features[i] ) 
	}
}

/**
	JSON string -> javascript object
	Produces javascript object from json string, or empty object if parse error occurred.
*/
export function catchJSONParse( string ) {
	var style;
	if (typeof string === "undefined") return {};
	if (string == null) return {};

	try	{ 
		style = JSON.parse( string );
	} catch (error){
		style = {}; 
		console.error(error);
	}
	return style;
}

export var kadasterKLICfeatureNameSpaces = {
								"imkl"					: "http://www.geostandaarden.nl/imkl/wibon" ,
								"net" 					: "http://inspire.ec.europa.eu/schemas/net/4.0",
								"us-net-common"			: "http://inspire.ec.europa.eu/schemas/us-net-common/4.0" 
						}
export var kadasterKLICFeatureTypes = [ 
								"us-net-common:UtilityLink",
								"imkl:AanduidingEisVoorzorgsmaatregel",
								"imkl:Annotatie",
								"imkl:Appurtenance",
								"imkl:Beheerder",
								"imkl:Belang",
								"imkl:BelangGeneriek",
								"imkl:Belanghebbende",
								"imkl:Bijlage",
								"imkl:BuisSpecifiek",
								"imkl:ContainerLeidingelement",
								"imkl:Diepte",
								"imkl:DiepteNAP",
								"imkl:DiepteTovMaaiveld",
								"imkl:Duct",
								"imkl:EffectcontourDodelijk",
								"imkl:EigenTopografie",
								"imkl:EisVoorzorgsmaatregelBijlage",
								"imkl:Elektriciteitskabel",
								"imkl:ExtraDetailinfo",
								"imkl:ExtraGeometrie",
								"imkl:ExtraInformatie",
								"imkl:GebiedsinformatieAanvraag",
								"imkl:GebiedsinformatieLevering",
								"imkl:Graafpolygoon",
								"imkl:IMKLBasis",
								"imkl:Informatiepolygoon",
								"imkl:Kabelbed",
								"imkl:KabelEnLeidingContainer",
								"imkl:KabelOfLeiding",
								"imkl:KabelSpecifiek",
								"imkl:Kast",
								"imkl:Label",
								"imkl:Leidingelement",
								/*"imkl:Maatvoering",*/
								"imkl:Mangat",
								"imkl:Mantelbuis",
								"imkl:Mast",
								"imkl:OlieGasChemicalienPijpleiding",
								"imkl:Organisatie",
								"imkl:Orientatiepolygoon",
								"imkl:Overig",
								"imkl:OverigSpecifiek",
								"imkl:Rioolleiding",
								"imkl:StedelijkWaterSpecifiek",
								"imkl:TechnischGebouw",
								"imkl:Telecommunicatiekabel",
								"imkl:ThermischePijpleiding",
								"imkl:Toren",
								"imkl:Transportroute",
								"imkl:Transportroutedeel",
								"imkl:TransportrouteRisico",
								"imkl:Utiliteitsnet",
								"imkl:Veiligheidsgebied",
								"imkl:Waterleiding"
						]
