var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-transact", title: "Transact", "ok-only": "" },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c("b-table", {
        attrs: {
          compact: "",
          "thead-class": "d-none",
          fields: _vm.tableDataFields,
          items: _vm.tableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }