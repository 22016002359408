var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    { attrs: { id: "modal-legend-graphic", title: "legend graphic" } },
    [_c("b-img", { attrs: { src: _vm.url } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }