'use strict';

import GML3             from 'ol/format/GML3';
import {WFS}            from 'ol/format.js';

import {FeatureStates} 	from './globals.js';


/**
	loop over the featuers in the layer and prepare for wfs transact
*/
export function saveFeatures ( layer ) {
	if ( ! layer.get('isSaveable') ) {
		return
	}
	var toDelete = [];
	var toInsert = [];
	var toUpdate = [];


	if (layer.get('isSaveable')) {
		map.get('app').$refs.modalTransact.open();
		let source = layer.getSource();
		let features = source.getFeatures();
		for ( var i = 0; i < features.length; i++ ) { 
			let feature = features[ i ];
			let state = feature.get( 'state' );
    		switch ( state) {
        		case FeatureStates.INSERT:
					toInsert.push ( feature );
					break;
        		case FeatureStates.DELETE:
					toDelete.push ( feature );
					break;
        		case FeatureStates.UPDATE:
					toUpdate.push ( feature );
					break;
			}
			feature.unset( 'state');
		}
		if ( ! ( toDelete && toInsert && toUpdate ) ){
			alert('ERROR: nothing the save! Please contact the adminstrator')
		}
		map.get('app').$refs.modalTransact.start();
		transactWFS( toInsert, toUpdate, toDelete, source );
		layer.set('isModified', false);
		//map.get('app').$refs.modalTransact.close();
	}
}


/**
	commit changes to server
*/
function transactWFS ( toInsert, toUpdate, toDelete, source) {

 	var formatGML = new GML3( {
        featureNS:      source.get('featureNS'),
        featureType:    'feature:' + source.get('featureType'),
		srsName:		"EPSG:28992"
    });

	var formatWFS = new WFS({
		gmlFormat: formatGML
	});

 	var node;
	var xs = new XMLSerializer();
	node = formatWFS.writeTransaction( toInsert, toUpdate, toDelete, formatGML );

    var payload = xs.serializeToString(node);

	let url = source.get('wfstUrl');

	fetch( url, {
        method		:	'POST',
		headers		: 	{ 'Content-Type': 'text/xml' },
        body		: 	payload
    })
	.then ( response => response.text() )
	.then ( function( data ) { 
		if (data.indexOf("ExceptionReport") > -1) {
			map.get('app').$refs.modalTransact.showResult( { transactionSummary: {"transactionError": data } } );
		} else {
			var transactResult = formatWFS.readTransactionResponse( data );
			map.get('app').$refs.modalTransact.showResult( transactResult );
		}
		source.refresh();
    });
}
