var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$root.isEditing
    ? _c(
        "div",
        [
          !_vm.isEnabled
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "shortkey",
                      rawName: "v-shortkey.once",
                      value: ["ctrl", "r"],
                      expression: "['ctrl', 'r']",
                      modifiers: { once: true },
                    },
                  ],
                  attrs: { title: "archis informatie [CTRL+R]" },
                  on: { shortkey: _vm.toggleActive, click: _vm.toggleActive },
                },
                [_vm._v(" \n\t\t\t\t\tarchis\n\t\t\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEnabled
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "shortkey",
                      rawName: "v-shortkey.once",
                      value: ["ctrl", "r"],
                      expression: "['ctrl', 'r']",
                      modifiers: { once: true },
                    },
                  ],
                  attrs: { variant: "warning" },
                  on: { shortkey: _vm.toggleActive, click: _vm.toggleActive },
                },
                [
                  _vm.isFetching
                    ? _c("b-spinner", { attrs: { small: "", type: "grow" } })
                    : _vm._e(),
                  _vm._v("\n\t\t\t\t\tarchis\n\t\t\t\t"),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }