<template>
	<b-modal 
		@show		="updateData"
		id			="modal-download-shape-data" 
		title		="Download data">
		 Hier staat uw data: <b-link :href="link">Link</b-link>
  	</b-modal>
</template>


<script>
import GeoJSON from 'ol/format/GeoJSON';

export default {
	data()  {
		return { 
			link : ''
		}
	},
	methods: {
		updateData: function () {
			let layer = this.$parent.myObject
			if ( layer.getSource().getFeatures().length == 0 ) {
				return
			}
			//let format = new GML3({
            			//featureNS		: 'http://www.tinyows.org/',
            			//featureType		: layer.getSource().getFormat().getFeatureType(),
            			//srsName			: "EPSG:28992",
            			//featurePrefix	: 'tows'
        			//});
			var format = new GeoJSON(
        	{
            	'dataProjection':       "EPSG:28992",
            	'featureProjection':    "EPSG:28992",
            	'geometryName':         layer.getSource().getFeatures()[0].getGeometryName()
        	});

      		let data = format.writeFeatures( 
					layer.getSource().getFeatures(),
					{
						decimals: 2
					}
					
			)
			fetch( "saveGISData.php?type=shape", { 
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify( { 'features': data })
			})
			.then( response => response.json())
			.then( data => { this.link =  'saveGISData.php?type=shape&file=' + data.filename } )
		}
	}
}
</script>
<style>
</style>
