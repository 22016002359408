'use strict';


/**
 * @module InteractionDelete
 */
import EventType 					from 'ol/events/EventType.js';
import {targetNotEditable} 			from 'ol/events/condition.js';
import Interaction, {zoomByDelta} 	from 'ol/interaction/Interaction.js';


/**
 * @typedef {Object} Options
 * @property {number} [duration=100] Animation duration in milliseconds.
 * @property {import("../events/condition.js").Condition} [condition] A function that
 * takes an {@link module:ol/MapBrowserEvent~MapBrowserEvent} and returns a
 * boolean to indicate whether that event should be handled. Default is
 * {@link module:ol/events/condition~targetNotEditable}.
 * @property {number} [delta=1] The zoom level delta on each key press.
 */


/**
 * @classdesc
 * Allows the user press Delete to delete selected features
 * @api
 */
class InteractionDelete extends Interaction {
  /**
   * @param {Options=} opt_options Options.
   */
  constructor(opt_options) {

    super({
      handleEvent: handleEvent
    });

    const options = opt_options ? opt_options : {};

    /**
     * @private
     * @type {import("../events/condition.js").Condition}
     */
    this.condition_ = options.condition ? options.condition : targetNotEditable;

    /**
     * @private
     * @type {number}
     */
    this.delta_ = options.delta ? options.delta : 1;

    /**
     * @private
     * @type {number}
     */
    this.duration_ = options.duration !== undefined ? options.duration : 100;

  }

}


/**
 */
function handleEvent(mapBrowserEvent) {
	let stopEvent = false;
	if (mapBrowserEvent.type == EventType.KEYDOWN || mapBrowserEvent.type == EventType.KEYPRESS) {
		const keyEvent = /** @type {KeyboardEvent} */ (mapBrowserEvent.originalEvent);
		const keyCode = keyEvent.keyCode;
		if (this.condition_(mapBrowserEvent) && (keyCode == 46 )) { //delete
			let layer = map.get('app').$refs.drawingTools.editedLayer
			if ( layer != null ) {
				layer.get('removeFeatures')( layer.controls['Select'].getFeatures() )
				layer.controls['Select'].getFeatures().clear() ;
			}
			mapBrowserEvent.preventDefault();
			stopEvent = true;
		}
	}
	return !stopEvent;
}

export default InteractionDelete;
