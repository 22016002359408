'use strict';
 
//require('jsts');
import Feature from 'ol/Feature';
import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser.js';
import GeometryFactory  from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import {UnaryUnionOp}  from 'jsts/org/locationtech/jts/operation/union';
import {BufferOp} from 'jsts/org/locationtech/jts/operation/buffer';
import {OverlayOp} from 'jsts/org/locationtech/jts/operation/overlay';

import {Point, LinearRing, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection} 	from 'ol/geom';
import {setFeatureStateInsert}   from './globals.js';
import {setFeatureStateUpdate}   from './globals.js';

//https://bjornharrtell.github.io/jsts/

/* Unions objects in 'selectedFeatures' to one opbject.
*/
export function unionFeatures( selectedFeatures ) {
	let parser = new OL3Parser();
  	parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection);
	let geometries = [];
	let factory = new GeometryFactory();
    for(let i = 0; i < selectedFeatures.length ; i ++) {
        let geometry = parser.read(  selectedFeatures[i].getGeometry());
		let userData = { 
			'label' : selectedFeatures[i].get('label') 
		};
		let a = 1;
		geometry.setUserData( userData );
        geometries.push( geometry );
    }
	let geomCollection = factory.createGeometryCollection( geometries )
	let uniontest = UnaryUnionOp.union( geomCollection );
	let parsedGeometry = parser.write( uniontest ) ;
	let newFeature = new Feature ( { 'the_geom': parsedGeometry } );
	newFeature.setGeometryName('the_geom');
	setFeatureStateInsert( newFeature )
	return newFeature
}

/* Cut second feature from first feature 
*/
export function differenceFeatures( featureA, featureB ) {
	let parser = new OL3Parser();
  	parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection);
	let g1 = parser.read( featureA.getGeometry());
	let g2 = parser.read( featureB.getGeometry() );
	let newGeometry = OverlayOp.difference( g1, g2 );
	let newGeometry2 = OverlayOp.intersection( g1, g2 );
	featureA.setGeometry( parser.write( newGeometry ));
	featureB.setGeometry( parser.write( newGeometry2 ));
	setFeatureStateUpdate( featureA )
	setFeatureStateUpdate( featureB )
}

/** Buffer objects in 'selectedFeatures' 
  * returns the feature with buffered geometry
*/
export function bufferFeature( feature, bufferSize ) {
	var parser = new OL3Parser();
  	parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
	let jstsGeom = parser.read( feature.getGeometry() )
	let bufferedGeometry = BufferOp.bufferOp( jstsGeom, bufferSize );
	feature.setGeometry( parser.write( bufferedGeometry ));
	setFeatureStateUpdate( feature )
	return feature
}

/* Intersect two objects in 'selectedFeatures' 
*/
function intersect() {
    var i, g1, g2, feature, features, layer, parser, selectedFeatures, intersectedGeometry, intersectedFeature, bufferedFeatures;

	if (!aok.layerArray.aLayerIsEdited()) return;

	//parser = new jsts.io.OpenLayersParser();
	parser = new OL3Parser();
    layer  = aok.layerArray.getCurrentEditedLayer().OLLayer;
    features = layer.features;
    selectedFeatures = [];
    bufferedFeatures = [];
    for(i = 0; i < features.length ; i ++) {
        if (features[i].renderIntent == "select") {
			feature = features[i];
            selectedFeatures.push(feature);
		}
	}
    if (selectedFeatures.length < 2) return;
	g1 = parser.read(selectedFeatures[0].geometry);
	g2 = parser.read(selectedFeatures[1].geometry);
	intersectedGeometry = g1.intersection( g2 );
	intersectedFeature = new OpenLayers.Feature.Vector(parser.write( intersectedGeometry ), {label: selectedFeatures[0].label, description: selectedFeatures[0].description });
	intersectedFeature.state = OpenLayers.State.INSERT;
	layer.addFeatures([intersectedFeature]);
	layer.redraw(); 
}

export function breakFeature( feature ) {

	let geometry = feature.getGeometry();
	let geometryType = geometry.getType();
	let features = [] 
	if ( geometryType == "MultiPolygon" ) {
		for (let i = 0; i < geometry.getPolygons().length; i ++ ) {
			let newFeature = new Feature ( { 'the_geom': geometry.getPolygon(i) } );
    		newFeature.setGeometryName('the_geom');
			setFeatureStateInsert( newFeature )
			features.push( newFeature )
		}
	} else if ( geometryType == "MultiPoint" ) {
		for (let i = 0; i < geometry.getPoints().length; i ++ ) {
			let newFeature = new Feature ( { 'the_geom': geometry.getPoint(i) } );
    		newFeature.setGeometryName('the_geom');
			newFeature.setGeometry( geometry.getPoint( i ))
			setFeatureStateInsert( newFeature )
			features.push( newFeature )
		}
	} else if ( geometryType == "MultiLineString" ) {
		for (let i = 0; i < geometry.getLineStrings().length; i ++ ) {
			let newFeature = new Feature ( { 'the_geom': geometry.getLineString(i) } );
    		newFeature.setGeometryName('the_geom');
			setFeatureStateInsert( newFeature )
			features.push( newFeature )
		}
	} else if ( geometryType == "GeometryCollection" ) {
		for (let i = 0; i < geometry.getGeometries().length; i ++ ) {
			let newFeature = new Feature ( { 'the_geom': geometry.getGeometries()[i] } );
    		newFeature.setGeometryName('the_geom');
			setFeatureStateInsert( newFeature )
			features.push( newFeature )
		}
	}
	return features
}

export function createGrid( selectedFeature, gridProps ) {
	var components = selectedFeature.getGeometry().getCoordinates();
	if (gridProps.intervalLength == 0 && !gridProps.isSleuvenGrid) { 
		alert("interval = 0"); 
		return;
	}
	let startNo = gridProps.firstNumber
	let gridPoints = []
	if ( gridProps.isLineGrid ) {
		let d = 0;
		let newP = getPointAt( d, components); 
		while ( newP ){
			let feature = new Feature ( { 'the_geom': newP}) 
			feature.setGeometryName('the_geom')
			feature.set ( 'label' , ( startNo++ ).toString() )
			setFeatureStateInsert( feature )
			gridPoints.push( feature)
			
			d = d + parseFloat( gridProps.intervalLength )
			newP = getPointAt( d, components)
		}
	} else { 
		// gebruik putlengte 15 en putbreedte 4, en gebruik
		// grid.intervalWidth = sleufafstand (HoH afstand dwars op sleufrichting) 
		// grid.intervalLength = sleufinterval (HoH afstand in de sleufrichting)
		let p0 = selectedFeature.getGeometry().getCoordinates()[0];
		let p1 = selectedFeature.getGeometry().getCoordinates()[1];
		let a = getSlope( p0, p1 );
		let x0 = p0[0];
		let y0 = p0[1];
		if ( gridProps.isSleuvenGrid ) {
		  for (let i = 0 ; i * gridProps.sleufAfstand < gridProps.width; i += 1) {
			for (let j = (i % 2 == 0 ? 0.0 : 0.5)  ; j * (2.0*gridProps.sleufInterval) < gridProps.length ; j += 1) {
				let x1 = x0 + (j * 2.0*gridProps.sleufInterval * Math.cos(a) - i * 0.5*gridProps.sleufAfstand * Math.sin(a))
				let y1 = y0 + (j * 2.0*gridProps.sleufInterval * Math.sin(a) + i * 0.5*gridProps.sleufAfstand * Math.cos(a))
				let x2 = x0 + ((j * 2.0*gridProps.sleufInterval + 1.0*gridProps.sleufLengte ) * Math.cos(a) - (i * 0.5*gridProps.sleufAfstand) * Math.sin(a))
				let y2 = y0 + ((j * 2.0*gridProps.sleufInterval + 1.0*gridProps.sleufLengte ) * Math.sin(a) + (i * 0.5*gridProps.sleufAfstand) * Math.cos(a))
				let x3 = x0 + ((j * 2.0*gridProps.sleufInterval + 1.0*gridProps.sleufLengte ) * Math.cos(a) - (i * 0.5*gridProps.sleufAfstand + 1.0*gridProps.sleufBreedte) * Math.sin(a))
				let y3 = y0 + ((j * 2.0*gridProps.sleufInterval + 1.0*gridProps.sleufLengte ) * Math.sin(a) + (i * 0.5*gridProps.sleufAfstand + 1.0*gridProps.sleufBreedte) * Math.cos(a))
				let x4 = x0 + (j * 2.0*gridProps.sleufInterval * Math.cos(a) - (i * 0.5*gridProps.sleufAfstand + 1.0*gridProps.sleufBreedte) * Math.sin(a))
				let y4 = y0 + (j * 2.0*gridProps.sleufInterval * Math.sin(a) + (i * 0.5*gridProps.sleufAfstand + 1.0*gridProps.sleufBreedte) * Math.cos(a))

  				let newP = new Polygon([[[x1, y1],[x2,y2], [x3,y3],[x4,y4],[x1,y1] ]])
				let feature = new Feature ( { 'the_geom': newP}) 
				feature.setGeometryName('the_geom')
				feature.set ( 'label' , ( startNo++ ).toString() )
				setFeatureStateInsert( feature )
				gridPoints.push( feature)
			}
		  }
		} else {
		  for (let i = 0 ; i * gridProps.intervalWidth < gridProps.width; i += 1) {
			for (let j = (i % 2 == 0 ? 0.0 : 0.5)  ; j * gridProps.intervalLength < gridProps.length ; j += 1) {
				let newP = new Point([ 
						x0 + (j * gridProps.intervalLength * Math.cos(a) - i * gridProps.intervalWidth * Math.sin(a)), 
						y0 + (j * gridProps.intervalLength * Math.sin(a) + i * gridProps.intervalWidth * Math.cos(a))
				] );
				let feature = new Feature ( { 'the_geom': newP}) 
				feature.setGeometryName('the_geom')
				feature.set ( 'label' , ( startNo++ ).toString() )
				setFeatureStateInsert( feature )
				gridPoints.push( feature)
			}
		  }
		}

	}
	return gridPoints
}


/** returns Openlayers.Geometry.Point or null
 ** targetDistance	distance in meters
 ** points  		Array of coordinates
 */
function getPointAt( targetDistance, points ){
	let d2;
	let d = 0;
	for (var i = 0; i < points.length - 1; i++){
		let p0 = points[i];
		let p1 = points[ i + 1]
		if ( d + distance( p0, p1 ) > targetDistance ) {
			d2 = targetDistance - d;
			let a = getSlope( p0, p1 );
			let x0 = p0[0];
			let y0 = p0[1];
			return new Point( [ x0 + Math.cos(a) * d2, y0 + Math.sin(a) * d2]);
		} else {
			d =  d + distance( p0, p1 );
		}
	}
	return null;
}

/**
*/
function distance( p0, p1) {
	return Math.sqrt( Math.pow( p0[0] - p1[0], 2 )  + Math.pow( p0[1] - p1[1], 2) );
}

/**
*/
function getSlope( p0, p1 ) {
	let x0 = p0[0];
	let y0 = p0[1];
	let x1 = p1[0];
	let y1 = p1[1];
	return  Math.atan2( (y1 - y0), (x1 - x0));
}

/**
	Nubmers the features starting with startNo
*/
export function renumber ( features ) {
	if (!aok.layerArray.aLayerIsEdited()) return;
    var aokLayer = aok.layerArray.getCurrentEditedLayer();

    var features = aokLayer.OLLayer.getSource().getFeatures();
	var skippedNr = 0;
    for (var i = 0; i < features.length ; i ++) {
		let feature = features[i];
		feature.set ( 'label' , (startNo + i - skippedNr).toString() ); //human numbering starts at 1
		feature.changed();
		aokLayer.setFeatureStateUpdate( feature );
	}
	aokLayer.setIsModified( true );
}

export function calculateCenter(geometry) {
  let center, coordinates, minRadius;
  const type = geometry.getType();
  if (type === 'Polygon') {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0].slice(1);
    coordinates.forEach(function (coordinate) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center = [x / i, y / i];
  } else if (type === 'LineString') {
    center = geometry.getCoordinateAt(0.5);
    coordinates = geometry.getCoordinates();
  } else {
    center = getCenter(geometry.getExtent());
  }
  let sqDistances;
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate) {
      const dx = coordinate[0] - center[0];
      const dy = coordinate[1] - center[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent())
      ) / 3;
  }
  return {
    center: center,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances,
  };
}
