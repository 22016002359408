<template>
 <b-modal
      id			="modal-question"
      ref			="modal-question"
      title			="vraag"
      @show			="resetModal"
      @hidden		="resetModal"
      @ok			="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label			= "labeltext"
          label-for			= "name-input"
          invalid-feedback	= "Invalid input"
          :state			= "answerState"
        >
          <b-form-input
            id				= "name-input"
			v-model			= "$v.answer.$model"
            :state			= "answerState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

</template>

<!-- *v-model = "anwer" -->


<script>

import { required, integer, decimal, url } from 'vuelidate/lib/validators'

export default {
	data() {
		return {
			labeltext			: "test",
			answer				: "",
			answerState			: null,
			resolvePromise		: null,
			rejectPromise		: null,
			answerType			: 'decimal'

		}
	},
	validations() {
		if (this.answerType == 'decimal' ) {	
			return {
				answer: {
						required,
						decimal
				}
			}
		} else if ( this.answerType == 'url' ) {
			return {
				answer: {
						required,
						url
				}
			}
		} else {
			return {
				answer: {
						required,
						integer
				}
			}
		}

	},
	methods: {
		checkFormValidity() {
        	//const valid = this.$refs.form.checkValidity()
        	//this.answerState = valid
        	//return valid
			const { $dirty, $error } = this.$v.answer
        	this.answerState = $dirty ? !$error : null
      		return this.answerState
		},
		resetModal() {
			this.answer = ''
			this.answerState = null
		},
		handleSubmit() {
			if (!this.checkFormValidity()) {
				return
			}
			// Hide the modal manually
			this.$nextTick(() => {
				this.$bvModal.hide('modal-question')
				this.resolvePromise( this.answer )
			})
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
				this.handleSubmit()
		},
		ask( text ) {
			this.labeltext = text
			if ( text.toLowerCase().includes('klic')) {
				this.answerType = 'url'
			} else if ( text.toLowerCase().includes('uffer')) {
				this.answerType = 'decimal'
			} else {
				this.answerType = 'integer'
			}
 			return new Promise((resolve, reject) => {
				this.$bvModal.show('modal-question')
        		this.resolvePromise = resolve;
        		this.rejectPromise = reject;
      		});
		}
	}
}
</script>

<style>
</style>

