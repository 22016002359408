<template>
	<b-modal 
		@show="updateData"
		id="modal-download-gml-data" 
		title="Download data">
		 Hier staat uw data: <b-link :href="link">Link</b-link>
  	</b-modal>
</template>


<script>
import GML from 'ol/format/GML';

export default {
	data()  {
		return { 
			link : ''
		}
	},
	methods: {
		updateData: function () {
			let layer = this.$parent.myObject
			if ( layer.getSource().getFeatures().length == 0 ) {
				return
			}
			let format = new GML({
            			featureNS		: 'http://www.tinyows.org/',
            			featureType		: layer.getSource().getFormat().getFeatureType(),
            			srsName			: "EPSG:28992",
            			featurePrefix	: 'tows'
        			});
      		let data = format.writeFeatures( 
					layer.getSource().getFeatures(),
					{
						decimals: 2
					}
					
			)
			fetch( "saveGISData.php?type=gml", { 
				method: 'post',
				headers: { 'Content-Type': 'gml+xml' },
				body: JSON.stringify( { 'features': data })
			})
			.then( response => response.json())
			.then( data => { this.link =  'saveGISData.php?type=gml&file=' + data.filename } )
		}
	}
}
</script>
<style>
</style>
