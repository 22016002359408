'use strict';


/**
 * Object -> Object
 * clones the feature, including geometry
 * and removes all attributes except those supported by this 'user' type
*/
export function cleanCloneFeature ( feature ) {
	var cleanFeature = feature.clone();
	cleanFeature.getKeys().slice().forEach( function( key, index ) {
		if (  key != "label"  &&  key != "description" && key != "style") {
			cleanFeature.unset( key );
		}
	});
	if ( !cleanFeature.getKeys().includes("label") ) {
		cleanFeature.set("label", "");
	}
	if ( !cleanFeature.getKeys().includes("description") ) {
		cleanFeature.set("description", "");
	}
	cleanFeature.setStyle( null); //this removes the default style function that works on the object, so the our own style on the layer work..
 	cleanFeature.setGeometryName('the_geom');
	cleanFeature.setGeometry( feature.getGeometry() );
	return cleanFeature;
}
