<template>
<div>
	<b-sidebar right  id="featureInfo" style="top:auto" title="Info" v-model="isShown"  shadow>
	  <b-overlay :show="isBusy" rounded="sm">
     	<div class="px-3 py-2">
		<div id="featureInfoTop"/>
			<pre>{{ preformatted_content }}</pre>
			{{ content }}
			<b-table 
							compact
							:tbody-tr-class     	="rowClass"
							:fields					="fields"
							thead-class				="d-none"
							striped hover :items	="items">
						<template #cell(value)="data">
    						<b-link v-if="!!data.item.url" target="_blank"  :href="data.item.url">link</b-link>
							<div v-else>
								{{data.value}}
							</div>
							
      					</template>
						<template #cell(html)="data">
        						<span v-html="data.value"></span>
      					</template>
			</b-table>
      	</div>
	  </b-overlay>
    </b-sidebar>
</div>
</template>

<script>


import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import EsriJSON 		 from 'ol/format/EsriJSON.js';

export default {
	data() {
		return {
			isShown	 						: false,
			preformatted_content			: "",
			content							: "",
			items							: [ ],
			fields							: [ 'key' , 'value', 'html' ],
			isBusy							: false,
			nrOfRequests					: 0
		}
	},
	methods: {
		startRequest : function (  ) {
			this.nrOfRequests ++ ;
			this.isBusy = true;
		},
		show : function ( rawText, layer ) {
			this.content = "";
			this.preformatted_content = "";
			this.items = [];
	
			if ( layer.get('infoFormat') == "esriJSON"  ) {
				let features = new EsriJSON().readFeatures( rawText);
				for ( let i = 0; i < features.length; i++  ) {
					this.extractAndAddItems(  features[i], rawText ) 
				}
			} else if ( layer.get('infoFormat') == "text/html"  ) {
				this.content = rawText ;
			} else if ( layer.get('infoFormat') == "text/plain" ) {
				this.preformatted_content = rawText ;
			} else if ( layer.get('infoFormat') == "aokgml" || layer.get('infoFormat') == "application/vnd.ogc.gml" ) {
				var allFeatures = new WMSGetFeatureInfo().readFeatures( rawText );
				map.get('tempLayer').getSource().clear();
				for ( let i = 0; i < allFeatures.length; i++  ) {
					let feature = allFeatures[i];
					this.extractAndAddItems(  feature, rawText ) 
					//let keys = feature.getKeys();
					//let k = 0;
					//for ( let j = 0; j < keys.length; j++  ) {
						//let key = keys[j];
						//let value = feature.get( key );
						//let htmlvalue = ''
						//let url ='';
						//if ( key == 'boundedBy' || key == 'the_geom' || key == 'geom' || key == 'geometry' ) {
							//continue;
						//} else if ( key == 'table' ) {
							//let table = rawText.substring(rawText.indexOf("<table>"), rawText.indexOf("</table>") );
							//value = ""
							//htmlvalue = table.replace(/&quot/g, '');
						//} else if (this.validateURL( value )) {
							////url = "<a href='" + value + "' target='_blank'>info</a>";
							//url = value;
							//value = '';
						//} 
						//this.items.push ( { 'key': key, 'value': value, 'url': url, 'index': k, html: htmlvalue } );
						//k++; //is a item index
					//}

					if ( feature.getGeometry() != null ) {
						map.get('tempLayer').getSource().addFeature( feature );
					}
					document.getElementById('featureInfoTop').scrollIntoView();
				}
			} else if ( Object.getPrototypeOf( layer ).constructor.name == 'VectorLayer' ){
				this.extractAndAddItems(  rawText ) //rawText is a feature object in this case
			}
			map.get('tempLayer').setMap( map ); //unmanned layer

			this.isShown = ( this.content != "" || this.preformatted_content != "" || this.items.length > 0 );  // show if there is something to show
			this.nrOfRequests-- ;
			if ( this.nrOfRequests < 0) {
				alert("nrOfRequests < 0: this should not happen. Please inform the administrator.")
			}
			
			if ( ! this.nrOfRequests ) {
				this.isBusy = false;
			}

		},
		extractAndAddItems( feature, rawText ) {
			let items = []
			let keys = feature.getKeys();
			let k = 0;
			for ( let j = 0; j < keys.length; j++  ) {
						let key = keys[j];
						let value = feature.get( key );
						let htmlvalue = ''
						let url ='';
						if ( key == 'boundedBy' || key == 'the_geom' || key == 'geom' || key == 'geometry' ) {
							continue;
						} else if ( key == 'table' ) {
							let table = rawText.substring(rawText.indexOf("<table>"), rawText.indexOf("</table>") );
							value = ""
							htmlvalue = table.replace(/&quot/g, '');
						} else if (this.validateURL( value )) {
							url = value;
							value = '';
						} 
						this.items.push ( { 'key': key, 'value': value, 'url': url, 'index': k, html: htmlvalue } );
						k++; //is a item index
			}
			return

		},
		validateURL: function ( textval ) { 
  				var urlregex = new RegExp(
       	 			"^(http:\/\/|https:\/\/|ftp:\/\/www.|www.){1}([0-9A-Za-z]+\.)");
  				return urlregex.test(textval);
		}, 
		clearTempLayer: function ( ) { 
			map.get('tempLayer').getSource().clear(); 
			map.get('tempLayer').setMap( null ); 		//and detach unmanned layer
		},
		rowClass(item, type) {
			if ( item.index == 0) {
				return 'table-primary';
			} 
      	},
		removeSelection() {
			let tempLayer = map.get('tempLayer')
			if ( tempLayer != null ) {
				tempLayer.getSource().clear();
			}
		}
	}

}
</script>

<style>
	#featureInfo {
		max-width: 50% !important;
	}
	
</style>


