<template>
	<b-modal 
		id		="modal-edit-layer" 
		@ok		="handleOk"
		@cancel	="handleCancel"
		v-model ="isShown"
		size	='lg'
		>
		<template #modal-title>
			Gegevens van de {{title}} ({{form.action}})
    	</template>

	  <b-overlay :show="showOverlay" rounded="sm">
    	<b-form @submit="onSubmit">
      		<b-form-group label-cols="3" id="input-group-name" label="naam" label-for="input-name">
        		<b-form-input 		
					id				="input-name" 
					:state			="validateState('newName')" 
					aria-describedby="input-live-feedback" 
					maxlength		='200' 
					v-model			="$v.form.newName.$model" 
					type			="text" 
					placeholder		="laagnaam" 
					trim
					autofocus ></b-form-input>
				<!-- This will only be shown if the preceding input has an invalid state -->
    			<b-form-invalid-feedback id="input-live-feedback">Enter 3 up to 200 letters, must be unique for each user</b-form-invalid-feedback>
    			<b-form-invalid-feedback v-if='!$v.form.newName.isUnique'>Layername is already registered</b-form-invalid-feedback>
      		</b-form-group>

      		<b-form-group label-cols="3" id="input-group-access" label="access" label-for="input-access">
				<b-form-select id="input-access" v-model="form.access" :options="accessOptions"></b-form-select>
      		</b-form-group>

      		<b-form-group label-cols="3" id="input-group-groups" label="groepen" label-for="input-groups">
				<b-form-checkbox-group
        				id					="input-groups"
        				v-model				="form.newGroup"
        				:options			="groupOptions"
        				name				="groups"
      			></b-form-checkbox-group>
				
      		</b-form-group>

			<div v-if="form.type == 'image'" >
      			<b-form-group label-cols="3" label="url:" label-for="input-url" >
        			<b-form-input type = 'url' trim :state = "validateState('url')" aria-describedby="url-fb" id="input-url" v-model="$v.form.url.$model" ></b-form-input>
    				<b-form-invalid-feedback id="url-fb">Enter at valid url</b-form-invalid-feedback>
      			</b-form-group>
      			<b-form-group label-cols="3" number label="x-min:" label-for="input-leftextent" >
        			<b-form-input type = 'number' :state="validateState('leftextent')" aria-describedby="leftextent-fb" id="input-leftextent" v-model="$v.form.leftextent.$model" ></b-form-input>
    				<b-form-invalid-feedback id="leftextent-fb">x-min must be between 647 and x-max (EPSG:29882)</b-form-invalid-feedback>
      			</b-form-group>
      			<b-form-group label-cols="3" number label="y-min:" label-for="input-bottomextent" >
        			<b-form-input type = 'number' :state="validateState('bottomextent')" aria-describedby="bottomextent-fb" id="input-bottomextent" v-model="$v.form.bottomextent.$model" ></b-form-input>
    				<b-form-invalid-feedback id="bottomextent-fb">Y must be between 276051 and 636456 (EPSG:29882)</b-form-invalid-feedback>
      			</b-form-group>
      			<b-form-group label-cols="3" number label="x-max:" label-for="input-rightextent" >
        			<b-form-input type = 'number' :state="validateState('rightextent')" aria-describedby="righttextent-fb" id="input-rightextent" v-model="$v.form.rightextent.$model" ></b-form-input>
    				<b-form-invalid-feedback id="rightextent-fb">x-max must be between x-min and 308975 (EPSG:29882)</b-form-invalid-feedback>
      			</b-form-group>
      			<b-form-group label-cols="3" number label="y-max:" label-for="input-topextent" >
        			<b-form-input type = 'number' :state="validateState('topextent')" aria-describedby="toptextent-fb" id="input-topextent" v-model="$v.form.topextent.$model" ></b-form-input>
    				<b-form-invalid-feedback id="topextent-fb">Y must be between 276051 and 636456 (EPSG:29882)</b-form-invalid-feedback>
      			</b-form-group>
      			<b-form-group label-cols="3" number label="rotation:" label-for="input-rotation" >
        			<b-form-input type = 'number' :state="validateState('rotation')" aria-describedby="rotation-fb" id="input-rotation" v-model="$v.form.rotation.$model" ></b-form-input>
    				<b-form-invalid-feedback id="rotation-fb">Rotatie must be between -180 and 180 degrees</b-form-invalid-feedback>
      			</b-form-group>
			</div>
      		<b-form-group label-cols="3" label="Omschrijving:" label-for="input-description" >
        		<b-form-textarea id="input-description" maxlength='500' v-model="form.description" rows="4"  max-rows="10" placeholder="Omschrijving" ></b-form-textarea>
      		</b-form-group>

    	  </b-form>
	    </b-overlay>
  	</b-modal>
</template>

<script>


import { url, sameAs, between, required, numeric, decimal, minLength, maxValue, and, minValue, maxLength } from 'vuelidate/lib/validators'

async function isUnique ( newName ) {
    if (newName === '') return false
    if (newName === this.form.name ) return true //newName is name which is ok
	const response = await fetch('layernameIsUnique.php?name=' + newName)
    return Boolean(await response.json())
}

function smallerThanRightExtent (value) {
	 return parseFloat( value ) < parseFloat( this.form.rightextent)
}
function largerThanLeftExtent (value) {
	 return parseFloat( value ) > parseFloat( this.form.leftextent)
}

function smallerThanTopExtent (value) {
	 return parseFloat( value ) < parseFloat( this.form.topextent)
}
function largerThanBottomExtent (value) {
	 return parseFloat( value ) > parseFloat( this.form.bottomextent)
}


export default {
	data() {
		return {
			isShown				: false,
			form: {
					action		: 'insert',
          			type		: 'image',
          			newName		: '',
					newGroup	: [],
					access		: "private",
          			description	: '',
					url			: '',
					topextent	: 500000,
					bottomextent: 300000,
					leftextent	: 200000,
					rightextent	: 300000,
					rotation	: "0" 
					
        	},

			accessOptions		: ["private", "public"],
			groupOptions		: [],
			showOverlay			: false
		}
	}, 
	validations() {
		if (this.form.type == 'image' ) {	
			return {
				form: {
					newName: {
						required,
						isUnique,
						minLength: 	minLength(3),
						maxLength: 	maxLength(200)
					},
					url: {
						required,
						url
					},
					topextent: {
						required,
						decimal,
						largerThanBottomExtent,
						maxValue: 	maxValue( 636456 )
					},
					bottomextent: {
						required,
						decimal,
						smallerThanTopExtent,
						minValue: 	minValue( 276051 )
					},
					leftextent: {
						required,
						decimal,
						smallerThanRightExtent,
						minValue: 	minValue( 647 )
					},
					rightextent: {
						required,
						decimal,
						largerThanLeftExtent,
						maxValue: 	maxValue( 308975)
					},
					rotation: {
						required,
						decimal,
						between: 	between( -180, 180)
					}
				}
			}
	    } else {  //vectorlayer
			return {
				form: {
					newName: {
						required,
						isUnique,
						minLength: 	minLength(3),
						maxLength: 	maxLength(200)
					}
				}
			}
		}
	},
	computed: {
			title: function() {
				if ( this.form.type == 'image' ) {
					return 'scanlaag'
				} else {
					return 'vectorlaag'
				} 
			}
	},
	methods: {
		validateState( value ) {
      		const { $dirty, $error } = this.$v.form[ value ];
      		return $dirty ? !$error : null;
    	},
		refresh: function () {
			Object.assign(this.$data, this.$options.data.call(this)); //reset data to inital values
			this.groupOptions =  []
			for (let group of this.$root.userdata.groups ) {
				this.groupOptions.push( { text: group, value: group });
			}
		},
		onSubmit: function ( evt ) {
			evt.preventDefault();
			this.handleOk();
		},
		add: function( type ) { //from managelayers modal
			this.refresh()
			this.form.type = type
			this.isShown = true
		},
		edit: function( layerRowdata ) { //from managelayers modal
			this.refresh()
			this.isShown 			= true
			this.form.action 		= 'update'
			this.form.type			= layerRowdata.type
			this.form.name			= layerRowdata.name
			this.form.newName		= layerRowdata.name
			this.form.id			= layerRowdata.id
			this.form.newGroup		= layerRowdata.groupname ? [ layerRowdata.groupname ] : null
			this.form.access		= layerRowdata.access
			this.form.description	= layerRowdata.description

			if ( this.form.type == 'image' ){
				this.form.url			= layerRowdata.layerspecs.url
				this.form.topextent		= layerRowdata.layerspecs.topextent
				this.form.bottomextent	= layerRowdata.layerspecs.bottomextent
				this.form.leftextent	= layerRowdata.layerspecs.leftextent
				this.form.rightextent	= layerRowdata.layerspecs.rightextent
				this.form.rotation		= layerRowdata.layerspecs.rotation
			}
		},
		handleOk: function ( evt ) {
			this.$v.form.$touch();
   			if (this.$v.form.$anyError) {
				evt.preventDefault();
        		return;
      		}
			this.$emit('changelayersdb');
			this.showOverlay = true;
       	 	fetch('submitLayerMetaData.php', {
				method	: 'post',
				headers	: { 'Content-Type': 'application/json' },
				body	: JSON.stringify( [ this.form ] )
			})
			.then( response => response.json())
			.then( data => {
				this.showOverlay = false;
				if ( data[0].success == "true" ) {
					this.$bvModal.msgBoxOk( "Laag: '" + this.form.newName + "' succesvol " + ( this.form.action == 'insert' ? 'aangemaakt' : 'bijgewerkt') );
					this.isShown = false;
				} else if ( data[0].success == "false" ) {
					this.$bvModal.msgBoxOk( "Fout: '" + this.form.newName + "'. De server meldt:" + data[0].error );
				}
    		});
		},
		handleCancel: function ( evt ) {
			evt.preventDefault();
			this.isShown = false;
		}
	}

}
</script>
<style>
</style>
