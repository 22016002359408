<template>
	<div>
			<b-form-group id="buttongroup" v-if="! isDisabled">
				<b-form-radio-group @change="toggleControl" v-model="checkedValue" :disabled="isDisabled" class="h-100"  buttons button-variant="warning">
					<b-form-radio value="Point"  v-b-tooltip title="Teken punten"> 
							<b-icon icon="dot" scale="3" aria-hidden="true"></b-icon> 
					</b-form-radio>
					<b-form-radio value="LineString"  v-b-tooltip title="Teken een lijn">
   						    <b-iconstack >
      							<b-icon stacked icon="check2" scale="1.2" shift-h="-6" shift-v="-4" ></b-icon>
      							<b-icon stacked icon="check2" scale="1.2" shift-h="7" shift-v="1"></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-12" shift-v="-4" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-8" shift-v="-7.5" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="1" shift-v="1" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="5" shift-v="-2.5" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="14" shift-v="6" ></b-icon>
    						</b-iconstack>
					</b-form-radio>
					<b-form-radio value="Polygon" v-b-tooltip title="Teken polygoon">
   						    <b-iconstack >
      							<b-icon stacked icon="pentagon" scale="1.2"></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-5" shift-v="-9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="+5" shift-v="-9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="+0" shift-v="+9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-9" shift-v="+2" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="+9" shift-v="+2" ></b-icon>
    						</b-iconstack>
					</b-form-radio>
					<b-form-radio value="Rectangle"  v-b-tooltip title="Teken rechthoeken"> 
   						    <b-iconstack >
								<b-icon stacked icon="square" scale="1.2" aria-hidden="true"></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="9" shift-v="9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-9" shift-v="-9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="9" shift-v="-9" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="-9" shift-v="9" ></b-icon>
    						</b-iconstack>
					</b-form-radio>
					<b-form-radio value="Modify"  v-b-tooltip title="Wijzig knopen van lijnen, polygonen en rechthoeken"> 
   							<b-iconstack >
      							<b-icon stacked icon="cursor" scale="1" ></b-icon>
      							<b-icon stacked icon="dot" scale="1.5" shift-h="8" shift-v="8" ></b-icon>
    						</b-iconstack>
					</b-form-radio>
					<b-form-radio value="Select" v-b-tooltip title="Selecteer objecten"> 
							<b-icon icon="cursor" scale="1.2" aria-hidden="true"></b-icon>
					</b-form-radio>
					<b-form-radio value="EditAttribute" v-b-tooltip title="Bewerk atributen"> 
   						    <b-iconstack >
      							<b-icon stacked icon="cursor" scale="0.9" ></b-icon>
      							<b-icon stacked icon="info" shift-h="-9" ></b-icon>
    						</b-iconstack>
					</b-form-radio>
					<b-form-radio value="Translate" v-b-tooltip title="Verplaats objecten"> 
								<b-icon icon="arrows-move" aria-hidden="true"></b-icon>
					</b-form-radio>
					<b-form-radio value="Rotate" v-b-tooltip title="Draai objecten"> 
								<b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
					</b-form-radio>
					<b-form-radio value="Del" v-b-tooltip title="Verwijder objecten"> 
								<b-icon icon="trash" aria-hidden="true"></b-icon>
					</b-form-radio>
					<b-badge variant="info" v-if="showObjectSize"> {{objectSizeHTML}} </b-badge>
					<b-badge variant="info" v-if="showSelectionSize"> {{selectedSize}} </b-badge>

				</b-form-radio-group>
				<b-button v-if="renumberingIsEnabled" @click="renumber" title="Nummer objecten" > 
						<b-icon icon="hash" aria-hidden="true"></b-icon>
				</b-button>
				<b-button v-if="gridIsEnabled" @click="grid" title="Maak een verspringend grid" > 
						<b-icon icon="grid3x3-gap" aria-hidden="true"></b-icon>
				</b-button>
				<b-button v-if="bufferIsEnabled" @click="buffer" title="vergroot het object met een buffer" > 
						<b-icon icon="bullseye" aria-hidden="true"></b-icon>
				</b-button>
				<b-button v-if="unionIsEnabled" @click="union" title="voeg de objecten samen" > 
							<b-icon icon="link" aria-hidden="true"></b-icon>
				</b-button>
				<b-button v-if="differenceIsEnabled" @click="difference" title="bereken verschil tussen twee objecten" > 
							<b-icon icon="subtract" aria-hidden="true"></b-icon>
				</b-button>
				<b-button v-if="breakIsEnabled" @click="breakFeatureAction" title="splits het samengestelde object in afzonderlijke objecten" > 
   					    <b-iconstack >
      						<b-icon stacked icon="link"  ></b-icon>
      						<b-icon stacked icon="scissors"  ></b-icon>
   					    </b-iconstack >
				</b-button>
				<b-button v-if="layerCanBeSaved" @click="save" variant="danger"  value="Save"> 
					<b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
				</b-button>
			</b-form-group>
		<modal-gridinput ref='modalGridinput'></modal-gridinput>
	</div>
</template>

<script>

import {saveFeatures} 			from './wfsFunctions.js';
import {unionFeatures} 			from './GIS.js';
import {bufferFeature} 			from './GIS.js';
import {breakFeature} 			from './GIS.js';
import {differenceFeatures} 		from './GIS.js';
import {createGrid} 			from './GIS.js';
import Feature          		from 'ol/Feature';
import {setFeatureStateInsert}  from './globals.js';
import {setFeatureStateUpdate}  from './globals.js';
import {setFeatureStateDelete}  from './globals.js';
import {FeatureStates}  		from './globals.js';
import {cleanCloneFeature} 		from './featureFunctions.js';

import ModalGridinput  			from './modal_gridinput.vue';


export default {
	data() {
		return {
 			isDisabled          : true,
 			unionIsEnabled      : false,
 			bufferIsEnabled     : false,
 			differenceIsEnabled : false,
 			renumberingIsEnabled: false,
 			gridIsEnabled     	: false,
 			breakIsEnabled      : false,
			checkedValue		: null,
			editedLayer			: null,
			layerCanBeSaved		: false,
			showObjectSize		: false,
			showSelectionSize	: false,
			objectSizeHTML		: "100m"
		}
	},
	computed: {
			selectedSize: function () {
				if ( this.showSelectionSize && this.editedLayer.controls.Select.getActive() ) {
						let features = this.editedLayer.controls.Select.getFeatures()
						let	typeCounts = {}
						for( let i = 0; i < features.getLength(); i++ ) {
							let geomType = features.item( i ).getGeometry().getType()
							if ( typeCounts.hasOwnProperty( geomType ) ) {
								typeCounts[ geomType ] ++
							} else {
								typeCounts[ geomType ] = 1
							}
						}
						let text = ''
						for (const [key, value] of Object.entries( typeCounts )) {
							text += (text.length > 0 ? ' ':'') + `${key}: ${value}`
						}
						return text
				} else {
					return ''
				}
			},
	},
	methods: {
		registerLayerForEdit: function ( layer ) {
			//TODO
			this.isDisabled = false;
			this.editedLayer = layer;
			if (!layer.get('isSaveable'	)){
				alert('WARNING: You cannot save this layer. Probably you are not the owner. You CANNOT save changes, but you can copy from this layer to another.')
			}
			map.addLayer( map.removeLayer( layer )); //moves it to the top
			if ( layer.get('isSaveable' )) {
				this.editedLayer.on('propertychange', this.layerIsModifiedListener ); //enable Save button after change
			}
			if ( layer.getSource().getFeatures().length ) {
				this.renumberingIsEnabled = true
			}
		},
		unregisterLayerForEdit: function ( layer ) {
			//TODO
			if ( this.editedLayer != layer ) {
				alert('ERROR: cannot unregister layer for edit, if it is not edited. Please contact system admin.')
				return
			}
			this.deactivateAll();
			this.isDisabled = true;
			this.editedLayer.un('propertychange', this.layerIsModifiedListener );
			this.editedLayer = null;
			this.checkedValue = null;
		},
		toggleControl: function( controlName ) {
			let controls = this.editedLayer.controls;

			//if ( controlName !== 'Translate' ) {
			this.deactivateAll(); //to be able to move selected features
			//}

			//now activate new control
			map.addInteraction( controls[ controlName ] );
    		controls[ controlName ].setActive( true );

			if ( controlName == 'Rotate' || controlName == 'Translate') {
        		map.addInteraction( controls[ 'Select' ] );
        		controls[ 'Select' ].setActive( true );
				this.showObjectSize = true;
    		} else if ( controlName == 'Modify' ) {
        		map.addInteraction( controls[ 'modify_' ] );
        		controls[ 'modify_' ].setActive( true );
    		} else if ( controlName == 'Select' ) {
				this.showSelectionSize = true;
        		map.addInteraction( controls[ 'DragBox' ] );
        		controls[ 'DragBox' ].setActive( true );
    		} else if ( controlName == 'Rectangle' ) {
				this.showObjectSize = true;
				map.on('pointermove', this.setRectangleSizeText )
    		} else if ( controlName == 'LineString' ) {
				this.showObjectSize = true;
				map.on('pointermove', this.setLineSizeText );
    		} else if ( controlName == 'Modify' || controlName == 'Polygon'  || controlName == 'LineString' || controlName == 'Point') {
        		map.addInteraction( controls[ 'Snap' ] );
        		controls[ 'Snap' ].setActive( true );
			}
		},
		deactivateAll: function( ) {
			this.showObjectSize = false
			this.showSelectionSize = false
			map.un('pointermove', this.setRectangleSizeText )
			map.un('pointermove', this.setLineSizeText )

			//Deactivate all drawing controls and clear selections
			let controls = this.editedLayer.controls;

			controls.Select.getFeatures().clear();

  			for (var key in controls) {
        		if ( controls[key] ) {
            		map.removeInteraction( controls[key] );
					controls[key].setActive ( false ) ;
				}
			}
		},
		save: function () {
			saveFeatures( this.editedLayer );
		},
		enableRenumberingButton: function () {
			this.renumberingIsEnabled = true 
		},
		disableRenumberingButton: function () {
			this.renumberingIsEnabled =  false
		},
		renumber: function() {
			this.$root.$refs.modalQuestion.ask('Wat is het eerste nummer?')
			.then( startNo => {
            	let features = this.editedLayer.getSource().getFeatures()
				features.sort(function( featureA,  featureB) {
  					// Compare the 2 dates
					let keyA = parseInt( featureA.get('label' ) )
					let keyB = parseInt( featureB.get('label' ))
  					if ( keyA < keyB) return -1;
  					if (keyA > keyB) return 1;
  					return 0;
				})
				features.forEach( feature => {
					feature.set ( 'label' , ( startNo++ ).toString() )
					feature.changed()
					setFeatureStateUpdate( feature )
				})
				this.editedLayer.set('isModified', true )
			})
		},
		enableGridButton: function () {
			this.gridIsEnabled = true 
		},
		disableGridButton: function () {
			this.gridIsEnabled = false
		},
		enableBufferButton: function () {
			this.bufferIsEnabled = true 
		},
		disableBufferButton: function () {
			this.bufferIsEnabled = false
		},
		enableDifferenceButton: function () {
			this.differenceIsEnabled = true
		},
		disableDifferenceButton: function () {
			this.differenceIsEnabled = false
		},
		grid: function () {
			this.$refs.modalGridinput.askGridProps()
			.then( gridProps => {
            	let selectedFeature = this.editedLayer.controls['Select'].getFeatures().getArray()[0]
				let gridPoints = createGrid( selectedFeature, gridProps )
				this.editedLayer.getSource().addFeatures( gridPoints )
				this.editedLayer.set('isModified', true )
			})
		},
		buffer: function () {
			this.$root.$refs.modalQuestion.ask('Hoe groot moet de buffer zijn (meters)?')
			.then( bufferSize => {
            	let selectedFeature = this.editedLayer.controls['Select'].getFeatures().getArray()[0]
				bufferFeature( selectedFeature, bufferSize )
				this.editedLayer.set('isModified', true );
			})
		},
		enableUnionButton: function () {
			//because of https://github.com/openlayers/openlayers/issues/4220
			//for now only accept homogeneous collections
			let features = this.editedLayer.controls['Select'].getFeatures()
			let type = features.item(0).getGeometry().getType()
			for( let i = 1; i < features.getLength(); i++ ){
				if ( features.item(i).getGeometry().getType() != type ) {
					this.disableUnionButton()
					return; //do not enable union
				}
			}
 			this.unionIsEnabled  = true;
		},
		disableUnionButton: function () {
 			this.unionIsEnabled  = false;
		},
		union: function() {
			let layer = this.editedLayer
			let selectedFeatures = layer.controls['Select'].getFeatures().getArray()
			let newFeature = unionFeatures( selectedFeatures )
    		layer.getSource().addFeature( newFeature )
			layer.get('removeFeatures')( selectedFeatures )
			this.disableUnionButton()
		},
		difference: function() {
			let layer = this.editedLayer
			let selectedFeatures = layer.controls['Select'].getFeatures().getArray()
			differenceFeatures( selectedFeatures[0] , selectedFeatures[1] )
			//layer.get('removeFeatures')( [ selectedFeatures[1] ] ) //remove the second feature
			this.editedLayer.set('isModified', true );
			this.disableDifferenceButton()
		},
		enableBreakButton: function () {
 			this.breakIsEnabled  = true;
		},
		disableBreakButton: function () {
 			this.breakIsEnabled  = false;
		},
		breakFeatureAction: function() {
			let layer = this.editedLayer
            let selectedFeature = layer.controls['Select'].getFeatures().getArray()[0]
			let newFeatures = breakFeature( selectedFeature )
			layer.getSource().addFeatures( newFeatures )
			layer.controls.Select.getFeatures().clear();
			layer.get('removeFeatures')( [selectedFeature] )
		},
		setRectangleSizeText: function () {
			let rectFeatures  = this.editedLayer.controls.Rectangle.getOverlay().getSource().getFeatures()
			if ( rectFeatures.length < 2 ) return
			let extent = rectFeatures[1].getGeometry().getExtent();
			let width = extent[2] - extent[0]
			let height  = extent[3] - extent[1]
			let text = ""
			if ( width < 100 && height < 100 ) {
					text = Math.round( width * 100.0 ) / 100  + "m x " + Math.round( height * 100.0 )/100 +"m"
			} else if ( width < 10000 && height< 10000 ){
					text = Math.round( width )  + "m x " + Math.round( height ) +"m"
			} else {
					text = Math.round( width / 1000 )  + "km x " + Math.round( height / 1000 ) +"km"
			}
			this.objectSizeHTML = text
		},
		setLineSizeText: function () {
			let features = this.editedLayer.controls.LineString.getOverlay().getSource().getFeatures()
			if ( features.length < 2 ) {
				return
			}
			let lineGeometry = features[0].getGeometry()
			if ( lineGeometry.getType() == 'LineString') {
				this.objectSizeHTML = lineGeometry.getLength()  + "m"
			}
		},
		layerIsModifiedListener: function( e ) {
			if ( e.key == 'isModified') {
 				if (e.target.get( e.key) == true  && this.editedLayer.get('isSaveable') ) {
						this.layerCanBeSaved = true;
				} else {
					this.layerCanBeSaved = false;
				}
			}
		},
		moveObjectsToLayer: function ( layerTo, action ) {
			if ( this.editedLayer != null ) {
				let layerFrom = this.editedLayer
				let features = layerFrom.controls['Select'].getFeatures().getArray()
				if ( ! features.length ) {
					this.$bvModal.msgBoxOk('fout: [objecten ' + action +']: geen objecten geselecteerd')
				} else {
					if ( layerFrom == layerTo && action == 'move') {
						this.$bvModal.msgBoxConfirm('fout: [objecten ' + action + ']: bron en doel zijn hetzelfde.')
					} else {
						this.$bvModal.msgBoxConfirm('Objecten ' + action + ' van ' + layerFrom.get('name') + ' naar:' + layerTo.get('name') +'? Wijzigingen worden direct opgeslagen.')
                		.then( continueStopEditing => {
							if ( continueStopEditing) {
								copyFeaturesToLayer( features, layerTo ) 
								if ( action == 'move' ) {
									layerFrom.get('removeFeatures')( features )
									saveFeatures( layerFrom );
									forceLayerRedraw( layerFrom ); 
								}
								saveFeatures( layerTo );
								forceLayerRedraw( layerTo ); 
								this.$bvModal.msgBoxOk('Gereed actie: ' + action + ' objecten: ' + layerFrom.get('name') + ' -> ' + layerTo.get('name') )
							} 
						})
					}
				}
			} else {
				this.$bvModal.msgBoxOk('fout: [objecten ' + action + ']: bewerk eerst een laag en selecteer objecten')
			}
		}
	},
	components: { ModalGridinput }
	
}

// copy features from the current edited layer, to 'LayerTo'
// The receiving layer is saved after changes
export function copyFeaturesToLayer( features, layerTo ) {
	for(let i = 0; i < features.length ; i ++) {
		addFeatureToLayer ( cleanCloneFeature( features[i] ), layerTo);
	}
	return 
}


/**
 * Object -> none
*/
function addFeatureToLayer ( feature, layer ) {
	setFeatureStateInsert( feature );
	layer.getSource().addFeature( feature );
}

function forceLayerRedraw ( layer ) {
	let features = layer.getSource().getFeatures()
	for( var i = 0; i < features.length ; i++){
		features[i].changed();
		break; //one is enough!
	}
}

</script>

<style> 
</style>
