<template>
	<!-- afdrukweergave modal -->
	<b-modal v-model="isShown" id="modal-afdrukweergave" title="Wissel naar afdrukweergave" hide-footer @ok="setPrintView">
   	 <p class="my-4">Druk op [ESC] om weer naar normale weergave de wisselen.</p>
    		<b-form @submit='setPrintView' >
      			<b-form-group id="input-group-1" label="breedte (pixels):" label-for="input-1"  >
        			<b-form-input id="input-1" v-model="width" type="number" placeholder="breedte (pixels)" autofocus ></b-form-input>
      			</b-form-group>
      			<b-form-group id="input-group-2" label="hoogte (pixels):" label-for="input-2" >
        			<b-form-input id="input-2" v-model="height" type="number" placeholder="hoogte (pixels)" ></b-form-input>
      			</b-form-group>
      			<b-form-group id="input-group-3" label="open nieuw venster:" label-for="input-3" >
        			<b-form-checkbox id="input-3" v-model="openInNewWindow" ></b-form-checkbox>
      			</b-form-group>
				<div v-if="$root.userdata  && $root.userdata.groups.includes('bva')">
      				<b-form-group id="input-group-4" label="statisch plaatje:" label-for="input-4" >
        				<b-form-checkbox :disabled = "!openInNewWindow" id="input-4" v-model="staticImage" ></b-form-checkbox>
      				</b-form-group>
				</div>
				<b-button type="submit" variant="primary">Afdrukweergave</b-button>
    		</b-form>
  	</b-modal>
	<!-- END afdrukweergave modal -->
</template>

<script>


export default {
	data() {
		return {
			isShown				: false,
			width				: 709,
			height				: 481,
			hasCoordCrosses		: false, //not used...
			openInNewWindow		: false,
			staticImage			: false
		}
	}, 
	methods: {
		setPrintView: function( event ) {
 			event.preventDefault()
			if ( this.openInNewWindow) {
				this.isShown = false
				let extent = map.getView().calculateExtent( map.getSize() )
				var layers = []
				for( let i = 0; i < map.getLayers().getLength(); i ++ ) {
					let layer = map.getLayers().item(i)
					if ( layer.getVisible() ) {
						layers.push ( {
							name		: layer.get( 'name' ),
							type		: layer.get( 'type' ),
							style		: layer.get( 'style' ),
							layerspecs	: layer.get( 'layerspecs' ),
							userspecs	: layer.get( 'userspecs' ),
							id			: layer.get( 'id' ),
							url			: layer.get( 'url' ),
							layerOpacity: layer.getOpacity()
						}) 
					}
				}
				let url = '?view=print&extent='+extent+'&height='+this.height+'&width='+this.width
				if (this.staticImage) {
					url = 'staticPrintScreen.php' + url
					var win=window.open('about:blank')
					if (win == null)  {
                        alert("Opening new window is blocked")
					} else {
						win.document.open();
						let bodywait = "<div id='loadingtext'>Cooking your image ;)</div>";
                    	bodywait +=    "<script> var count = 0; ";
						bodywait +=    "setInterval( function() { count++; document.getElementById('loadingtext').innerHTML = 'Cooking your image ;).' + new Array(count % 10).join('.'); }, 1000);";
						bodywait +=    '</scr';
						bodywait +=    'ipt>'; //anders begrijpt vim het niet
						win.document.write( bodywait );
                    	win.document.close();
       	 				fetch( url, {
							method	: 'post',
							headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
							body	: JSON.stringify( { 'layers' : layers } ) 
						})
						.then( response => response.text())
						.then( data => {
							win.document.open();
							win.document.write(data);
      			        	win.document.close();
						})

					}
				} else {
       	 			fetch( url, {
						method	: 'post',
						headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
						body	: JSON.stringify( { 'layers' : layers } ) 
					})
					.then( response => response.text())
					.then( data => {
						var win=window.open('about:blank')
						if (win == null)  {
                        	alert("Opening new window is blocked")
						} else {
							win.document.open();
							win.document.write(data);
      			        	win.document.close();
						}
					})
				}
			} else {
				document.documentElement.style.setProperty('--print-width',  this.width + 'px');
				document.documentElement.style.setProperty('--print-height', this.height + 'px');
				document.documentElement.style.setProperty('--scale-line-bottom', '10px');
				document.title = 'Aarde op de Kaart - PrintView'

				//make sure north arrow is visible
				let elementList = document.getElementsByClassName("ol-rotate ol-unselectable ol-control ol-hidden")
				if ( elementList.length) {
					elementList[0].classList.remove('ol-hidden')
				}

				map.setSize( [ this.width, this.height ] )
				this.$parent.isPrintView = true
				document.addEventListener( 'keyup' , setNormalView)
				//hack to save width/height, because isprintview=true destroys this widget....
				document.printViewSize = [ this.width, this.height ] 
			}
		},

	},
	mounted: function () {
		if ( document.printViewSize ) {	
			this.width = document.printViewSize[0]
			this.height = document.printViewSize[1]
			document.printViewSize  = null
		}
	}
}

function setNormalView( e ) {
	if (e.key === "Escape") { // escape key maps to keycode `27`
   			map.get('app').isPrintView = false

			//this layer is used for drawing grid lines during rotation
			map.get('coordRasterLayer').getSource().refresh() 

			map.setSize( [  document.body.clientWidth, document.body.clientHeight] )
			document.documentElement.style.setProperty('--scale-line-bottom', '10px');
			document.title = 'Aarde op de Kaart'
			document.removeEventListener( 'keyup' , setNormalView)
			document.dontShowModalSignin = 1
 	}
}
</script>

<style>
</style>
